import React, { Component } from 'react';
import $ from 'jquery';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from './../Api';
import Swal from 'sweetalert2';
import AceEditor from 'react-ace';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';


import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';
import 'brace/snippets/html';
import 'brace/ext/language_tools';

const SortableItem = SortableElement(({item,index}) => <div key={item.id} className="col-md-12 row div_edit_block">
  <div className="col-md-2">ID: {item.id}</div>
  <div className="col-md-3" >{item.name}</div>
  <div className="col-md-3" >{item.definition_name}</div>
  <div><textarea hidden>{item.description}</textarea></div>
  <div className="col-md-4">
    
    <button type="button" className="btn btn-primary btn-sm" onClick={ () => window.conf_Blocklyblocksso.editarBlocklyBlock(item.id) }><i className="fas fa-pencil-alt"></i> Editar</button> <button onClick={ () => window.conf_Blocklyblocksso.removerBlocklyBlock(item.id) } type="button" className="btn btn-danger btn-sm"><i className="far fa-trash-alt"></i> Remover</button>

  </div>

</div>);

const SortableList = SortableContainer(({items}) => {

  var s = 'calc('+window.$('body').height()+'px - 206px)';

  return (
 
    <div style={{ overflow:'auto',height:s,width:"100%"}}>


      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} item={value} />
      ))}
    </div>
    
  );
});


class Blockly_secao extends Component {
  
  constructor(props){

    super(props); 
    this.state = {blocks:[],tituloModal:'',botaoModal:''};
    this.getBlocklyBlock = this.getBlocklyBlock.bind(this);

    this.novoBlocklyBlock = this.novoBlocklyBlock.bind(this);
    this.editarBlocklyBlock = this.editarBlocklyBlock.bind(this);
    this.removerBlocklyBlock = this.removerBlocklyBlock.bind(this);

    window.conf_Blocklyblocksso = this;

  }

  onSortEnd = async ({oldIndex, newIndex}) => {


    await this.setState(({blocks}) => ({
      blocks: arrayMove(blocks, oldIndex, newIndex),
    }));


    var post = [];

    for (let index = 0; index < this.state.blocks.length; index++) {
     
      post.push(this.state.blocks[index].id);
      
    }


    await api.changeSequenceBlocks(post).then((res) => {

        this.setState({value:res.data,carregou:true});
      
    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
    });

    console.log(post);

  };

  async getBlocklyBlock(){

    await api.getBlocklyBlock().then((res) => {

        this.setState({blocks:res.data});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });

  }


  async saveBlocklyBlock(event){

    event.preventDefault();


    $("#definition").val(this.refs.aceEditor.editor.getValue());
    $("#generator").val(this.refs.aceEditor2.editor.getValue());
    $("#manual_xml").val(this.refs.aceEditor3.editor.getValue());


 


    var data = $('#formCadastroblock').serialize();
 
    if(this.state.blockEditor === ""){

      await api.newBlocklyBlock(data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('A block foi inserida.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
    
    }else{

      await api.updateBlocklyBlock(this.state.blockEditor,data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('As alterações foram salvas.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
    
    }

    window.$('div').modal('hide');
    this.getBlocklyBlock();
    
    return false; 


  }

  async removerBlocklyBlock(id){
 
    Swal.fire({
      title: 'Você tem certeza?', 
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não, cancelar!'
    }).then((result) => {

      if(result.value){
 
        api.deleteBlocklyBlock(id).then((res) => {

          toast.success('A block foi removida.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
          this.getBlocklyBlock();

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

        });


      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });
 

  }


   

  novoBlocklyBlock(){ 
  
    document.getElementById("formCadastroblock").reset();

    this.refs.aceEditor.editor.setValue('',-1);
    this.refs.aceEditor2.editor.setValue('',-1);
    this.refs.aceEditor3.editor.setValue('',-1);

    this.setState({tituloModal:'Nova block',botaoModal:'Cadastrar block',blockEditor:""});
    window.$('#blockModal').modal(); 


  }

  editarBlocklyBlock(id){ 
  
    document.getElementById("formCadastroblock").reset();

    this.setState({tituloModal:'Editar block',botaoModal:'Salvar alterações',blockEditor:id});
    window.$('#blockModal').modal();

    this.state.blocks.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(id)){

        $.each(item,function(index2,value2){

          window.$('input[name='+index2+']').val(value2);
          window.$('select[name='+index2+']').val(value2);
          window.$('textarea[name='+index2+']').val(value2);

        });

        console.log(item.definition);
        setTimeout(function(){ 

          this.refs.aceEditor.editor.setValue(item.definition,-1);
          this.refs.aceEditor2.editor.setValue(item.generator,-1);
          this.refs.aceEditor3.editor.setValue(item.manual_xml,-1);

        }.bind(this), 10);

      }

    }.bind(this));


  }

  componentDidMount(){ this.getBlocklyBlock(); window.app.loading(false); }
  
  render() {

 
    return (<>

          <link rel='stylesheet' id='mqueries-style-css' href='/css/principal.css' type='text/css' media='all' />

          <div className="section-inner" style={{paddingTop:'33px'}}>
            
            <div className="wrapper">
                
              <div className="section-title" style={{marginBottom:'30px'}}>

                  <h2 style={{color:'#fff',fontSize:'30px'}}>Blockly block</h2> 
                  <button type="submit" className="btn btn-outline-light" onClick={ () => this.getBlocklyBlock() }>Atualizar <i className="fas fa-sync-alt"></i></button> <button type="submit" className="btn btn-outline-light" onClick={ () => this.novoBlocklyBlock() } >Nova block <i className="fas fa-plus"></i></button>

              </div>

              <div>
                
                <div className="col-md-12 row div_edit_block" style={{backgroundColor:'#23222300'}}>
                  <div className="col-md-2">ID:</div>
                  <div className="col-md-3">Nome:</div>
                  <div className="col-md-3">Definição:</div>
                  <div className="col-md-4">Ações</div>
                </div>
                {

                  <SortableList  items={this.state.blocks}  onSortEnd={this.onSortEnd} />

                }
              </div>
              
            </div>
          </div>
 


 

        <div className="modal " id="blockModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
          <div className="modal-dialog"  role="document" style={{maxWidth:'100%'}}>
              <div className="modal-content">
                
              <br/>
              <center><h3 id="editarblock">{this.state.tituloModal}</h3></center>

                  <div className="modal-body">
                    
                    <form id="formCadastroblock" onSubmit={ (event) => this.saveBlocklyBlock(event) }>
                          
                      <div className="row">

                        <div className="col-md-4">
                          <div className="form-group">
                                <label>Nome:</label>
                                <input required type="text" name="name" className="form-control"  aria-describedby="emailHelp" /> 
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                                <label>Nome:</label>
                                <input required type="text" name="definition_name" className="form-control"  aria-describedby="emailHelp" /> 
                          </div>
                        </div>
 
                        <div className="col-md-4">
                          <div className="form-group">
                                <label >Descrição:</label>
                                <textarea type="text" name="description" className="form-control" aria-describedby="emailHelp"></textarea>
                          </div>
                        </div>


                        <textarea hidden type="text" name="definition" id="definition" className="form-control" aria-describedby="emailHelp"></textarea>
                        <textarea hidden type="text" name="generator" id="generator" className="form-control" aria-describedby="emailHelp"></textarea>
                        <textarea hidden type="text" name="manual_xml" id="manual_xml" className="form-control" aria-describedby="emailHelp"></textarea>

                         

                      </div>


                      <div className="row">

                        <div className="col-md-4">
                          <div className="form-group">
                                <label>Definition:</label>
                                <AceEditor
                                    mode="javascript"
                                    ref="aceEditor"
                                    theme="monokai" 
                                    name="aceEditor"
                                    editorProps={{$blockScrolling: true}}
                                    width="auto"
                                    height={400}
                                    showPrintMargin={false}

                                  />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                                <label >Generator:</label>
                                <AceEditor
                                  mode="javascript"
                                  ref="aceEditor2"
                                  theme="monokai" 
                                  name="aceEditor2"
                                  editorProps={{$blockScrolling: true}}
                                  width="auto"
                                  height={400}
                                  showPrintMargin={false}

                                />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                                <label >Manual XML:</label>
                                <AceEditor
                                  mode="javascript"
                                  ref="aceEditor3"
                                  theme="monokai" 
                                  name="aceEditor3"
                                  editorProps={{$blockScrolling: true}}
                                  width="auto"
                                  height={400}
                                  showPrintMargin={false}

                                />
                          </div>
                        </div>

                 

                      </div>

                      <button type="submit" className="btn btn-primary" style={{width:'100%'}} >{this.state.botaoModal}</button>

                    </form>

                  </div> 

              </div>
            </div>
          </div>








    </>);

  }


}

export default Blockly_secao;
