import axios from 'axios';


//console.log('ip definido: '+sessionStorage.getItem('backend'));
var api = axios.create({secure: false });

if(window.typefront === undefined){

	window.typefront = "jr";

}

if(window.parseQueryString().typefront !== undefined){ window.typefront = window.parseQueryString().typefront; }

if(window.typefront === "default"){ window.default_dd = "/studio/get"; }
if(window.typefront === "jr"){ window.default_dd = "/studio2/get"; }
if(window.typefront === "jr_2"){ window.default_dd = "/studio3/get"; }

var apis = {
	
	duplicateProject: (user,projetoID,normal,email,name) => api.get(sessionStorage.getItem('backend')+'/integration/user?name='+name+'&user='+user+'&projetoID='+projetoID+'&normal='+normal+'&email='+email,{headers: {session: sessionStorage.getItem('session')}}),

	login: (data) => api.post(sessionStorage.getItem('backend')+'/auth/password',data),
	
	games: (data) => api.get(sessionStorage.getItem('backend')+'/game/list',{headers: {session: sessionStorage.getItem('session')}}),

	newGames: (dados) => api.post(sessionStorage.getItem('backend')+'/game',dados,{headers: {session: sessionStorage.getItem('session')}}),
	getGame: (id) => api.get(sessionStorage.getItem('backend')+'/game/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	deleteGame: (id) => api.delete(sessionStorage.getItem('backend')+'/game/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getListToolBox: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie',{headers: {session: sessionStorage.getItem('session')}}),
	  
	getToolBox: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/xmltoolbox/'+id+'?typefront='+window.typefront,{headers: {session: sessionStorage.getItem('session')}}),
	 
	getAllJsBlocks: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/blocks',{headers: {session: sessionStorage.getItem('session')}}),

	getFiles: (id) => api.get(sessionStorage.getItem('backend')+'/game/files/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getFile: (id) => api.get(sessionStorage.getItem('backend')+'/game/file/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	saveSettings: (id,dados) => api.put(sessionStorage.getItem('backend')+'/game/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),

	updateFile: (id,dados) => api.put(sessionStorage.getItem('backend')+'/game/file/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	updateAsset: (id,dados) => api.put(sessionStorage.getItem('backend')+'/asset/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),

	createFile: (dados) => api.post(sessionStorage.getItem('backend')+'/game/file/',dados,{headers: {session: sessionStorage.getItem('session')}}),

	removeFile: (id) => api.delete(sessionStorage.getItem('backend')+'/game/file/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	upload: (dados) => api.post(sessionStorage.getItem('backend')+'/upload/',dados,{headers: {session: sessionStorage.getItem('session'),'Content-Type': 'multipart/form-data'}}),
	postAsset: (dados) => api.post(sessionStorage.getItem('backend')+'/asset/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	
	getAllAssets: (id) => api.get(sessionStorage.getItem('backend')+'/asset/game/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	removeAsset: (id) => api.delete(sessionStorage.getItem('backend')+'/asset/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	dados_video: (video) => api.get(sessionStorage.getItem('backend')+'/video/dados?id='+video,{headers: {session: sessionStorage.getItem('session')}}),

	getStudioOptions: (id) => api.get(sessionStorage.getItem('backend')+'/studiooptions/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getDefaultstartcode: () => api.get(sessionStorage.getItem('backend')+'/asset/initialcode/',{headers: {session: sessionStorage.getItem('session')}}),

	getAnimations: (id) => api.get(sessionStorage.getItem('backend')+'/asset/animation/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	newAnimation: (dados) => api.post(sessionStorage.getItem('backend')+'/asset/animation/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	
	updateAnimation: (id,dados) => api.put(sessionStorage.getItem('backend')+'/asset/animation/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	
	updateFileImportAsset: (id,dados) => api.put(sessionStorage.getItem('backend')+'/game/file/importAssets/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	removeAnimation: (id) => api.delete(sessionStorage.getItem('backend')+'/asset/animation/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	getUsers: () => api.get(sessionStorage.getItem('backend')+'/user/',{headers: {session: sessionStorage.getItem('session')}}),
	updateUser: (id,dados) => api.put(sessionStorage.getItem('backend')+'/user/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newUser: (dados) => api.post(sessionStorage.getItem('backend')+'/user/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteUser: (id) => api.delete(sessionStorage.getItem('backend')+'/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),
 
	getToolboxcategorie: () => api.get(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/',{headers: {session: sessionStorage.getItem('session')}}),
	updateToolboxcategorie: (id,dados) => api.put(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newToolboxcategorie: (dados) => api.post(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteToolboxcategorie: (id) => api.delete(sessionStorage.getItem('backend')+'/blockly/toolboxcategorie/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getBlocklySection: () => api.get(sessionStorage.getItem('backend')+'/blockly/blocklysection/',{headers: {session: sessionStorage.getItem('session')}}),
	updateBlocklySection: (id,dados) => api.put(sessionStorage.getItem('backend')+'/blockly/blocklysection/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newBlocklySection: (dados) => api.post(sessionStorage.getItem('backend')+'/blockly/blocklysection/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteBlocklySection: (id) => api.delete(sessionStorage.getItem('backend')+'/blockly/blocklysection/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	getBlocklyBlock: () => api.get(sessionStorage.getItem('backend')+'/blockly/blocklyblock/',{headers: {session: sessionStorage.getItem('session')}}),
	updateBlocklyBlock: (id,dados) => api.put(sessionStorage.getItem('backend')+'/blockly/blocklyblock/'+id,dados,{headers: {session: sessionStorage.getItem('session')}}),
	newBlocklyBlock: (dados) => api.post(sessionStorage.getItem('backend')+'/blockly/blocklyblock/',dados,{headers: {session: sessionStorage.getItem('session')}}),
	deleteBlocklyBlock: (id) => api.delete(sessionStorage.getItem('backend')+'/blockly/blocklyblock/'+id,{headers: {session: sessionStorage.getItem('session')}}),
 
	addAssetScene: (asset,gamefiles) => api.post(sessionStorage.getItem('backend')+'/asset/addscene/',{asset:asset,gamefile:gamefiles},{headers: {session: sessionStorage.getItem('session')}}),

 	verifyAcess: () => api.get(sessionStorage.getItem('backend')+'/auth/verify/',{headers: {session: sessionStorage.getItem('session')}}),

 	 

	salvarSecaoToolboxcategorie: (categoria,sections) => api.put(sessionStorage.getItem('backend')+'/blockly/salvarSecaoToolboxcategorie/',{category:categoria,sections:sections},{headers: {session: sessionStorage.getItem('session')}}),
	salvarSecaoBlocks: (section,blocks) => api.put(sessionStorage.getItem('backend')+'/blockly/salvarSecaoBlocks/',{section:section,blocks:blocks},{headers: {session: sessionStorage.getItem('session')}}),

 

	//usado na configuracao de seções
	getAllJsBlock: (id) => api.get(sessionStorage.getItem('backend')+'/blockly/block',{headers: {session: sessionStorage.getItem('session')}}),

	//para pegar o helper
	getStudioHelper: (id) => api.get(sessionStorage.getItem('backend')+window.default_dd,{headers: {session: sessionStorage.getItem('session')}}),
	
	getStudioHelper1: (id) => api.get(sessionStorage.getItem('backend')+'/studio/get',{headers: {session: sessionStorage.getItem('session')}}),
	getStudioHelper2: (id) => api.get(sessionStorage.getItem('backend')+'/studio2/get',{headers: {session: sessionStorage.getItem('session')}}),
	getStudioHelper3: (id) => api.get(sessionStorage.getItem('backend')+'/studio3/get',{headers: {session: sessionStorage.getItem('session')}}),


	setStudioHelper1: (file) => api.post(sessionStorage.getItem('backend')+'/studio/',{file:file},{headers: {session: sessionStorage.getItem('session')}}),
	setStudioHelper2: (file) => api.post(sessionStorage.getItem('backend')+'/studio2/',{file:file},{headers: {session: sessionStorage.getItem('session')}}),
	setStudioHelper3: (file) => api.post(sessionStorage.getItem('backend')+'/studio3/',{file:file},{headers: {session: sessionStorage.getItem('session')}}),
	




	changeOrderFileList: (file,order) => api.put(sessionStorage.getItem('backend')+'/game/file/'+file,{sequence:order},{headers: {session: sessionStorage.getItem('session')}}),



	//Para alterara sequencia dos blocos
	changeSequenceBlocks: (order) => api.put(sessionStorage.getItem('backend')+'/blockly/savesequence/blocks',{order:order},{headers: {session: sessionStorage.getItem('session')}}),

	//Para alterar a sequencia dos blocos dentro da secao
	changeSequenceBlocksSection: (order,section) => api.put(sessionStorage.getItem('backend')+'/blockly/savesequence/blockssection',{order:order,section:section},{headers: {session: sessionStorage.getItem('session')}}),

	 
	 
}

export default apis;
