import React, { Component } from 'react';
import $ from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from './../Api';
import Swal from 'sweetalert2'; 


import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';

import 'brace/snippets/html';
import 'brace/ext/language_tools';



class Blockly_categoria extends Component {
  
  constructor(props){

    super(props); 
    this.state = {categorias:[],sections:[],tituloModal:'',botaoModal:''};
    this.getToolboxcategorie = this.getToolboxcategorie.bind(this);

    this.novoToolboxcategorie = this.novoToolboxcategorie.bind(this);
    this.editarToolboxcategorie = this.editarToolboxcategorie.bind(this);
    this.removerToolboxcategorie = this.removerToolboxcategorie.bind(this);

    this.editarSecaoToolboxcategorie = this.editarSecaoToolboxcategorie.bind(this);
    this.salvarSecaoToolboxcategorie = this.salvarSecaoToolboxcategorie.bind(this);
    this.getAllSections = this.getAllSections.bind(this);

    window.Blockly_categoria = this;
  
  }


  async getToolboxcategorie(){

    await api.getToolboxcategorie().then((res) => {

        this.setState({categorias:res.data});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });

  }




  async getAllSections(){

    await api.getBlocklySection().then((res) => {

        this.setState({sections:res.data});

    }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
    
      });

  }


  async saveToolboxcategorie(event){

    $("#manual_xml").val(this.refs.aceEditor.editor.getValue());

    event.preventDefault();

    var data = $('#formCadastroCategoria').serialize();
 
    if(this.state.categoriaEditor === ""){

      await api.newToolboxcategorie(data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('A ToolBox foi inserida.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
    
    }else{

      await api.updateToolboxcategorie(this.state.categoriaEditor,data).then((res) => {

        return res.data;

      }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);
          return false;

      });

      toast.success('As alterações foram salvas.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
    
    }

    window.$('div').modal('hide');
    this.getToolboxcategorie();
    this.getAllSections();

    return false; 


  }

  async removerToolboxcategorie(id){
 
    Swal.fire({
      title: 'Você tem certeza?', 
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não, cancelar!'
    }).then((result) => {

      if(result.value){
 
        api.deleteToolboxcategorie(id).then((res) => {

          toast.success('A ToolBox foi removida.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
          this.getToolboxcategorie();
          this.getAllSections();

        }).catch(error => {

            console.log(error.data);
            console.log(error);
            console.log(error.response);
            return false;

        });


      }else if (result.dismiss === Swal.DismissReason.cancel) {
       

      }

    });
 

  }


   

  novoToolboxcategorie(){ 
    
    this.refs.aceEditor.editor.setValue('',-1);

    document.getElementById("formCadastroCategoria").reset();

    this.setState({tituloModal:'Nova ToolBox',botaoModal:'Cadastrar toolbox',categoriaEditor:""});
    window.$('#categoriaModal').modal(); 


  }

  editarToolboxcategorie(id){ 
  
    document.getElementById("formCadastroCategoria").reset();

    this.setState({tituloModal:'Editar ToolBox',botaoModal:'Salvar alterações',categoriaEditor:id});
    window.$('#categoriaModal').modal();

    this.state.categorias.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(id)){

        $.each(item,function(index2,value2){

          console.log(index2+' '+value2);
          window.$('input[name='+index2+']').val(value2);
          window.$('select[name='+index2+']').val(value2);
          window.$('textarea[name='+index2+']').val(value2);

        });

        setTimeout(function(){ this.refs.aceEditor.editor.setValue(item.manual_xml,-1); }.bind(this), 100);

      }

    }.bind(this));


  }


  editarSecaoToolboxcategorie(categoria){

    $('.checkSelectImport44').prop('checked', false);

    window.$("#secaoCategoriaModal").modal();

    this.state.categorias.forEach(function(item,index,array){

      if(parseInt(item.id) === parseInt(categoria)){

        this.setState({tituloModal:'Seções da ToolBox '+item.name,botaoModal:'Salvar alterações',categoriaEditor:categoria});

        item.sections.forEach(function(item2,index2,array2){ 

          $('.checkSelectImport44[value='+item2+']').prop('checked', true); 

        });

      }

    }.bind(this));
    
  }


  async salvarSecaoToolboxcategorie(event){

    event.preventDefault();

    var secoes = [];

    window.$(".checkSelectImport44:checked").each(function(){

      secoes.push($(this).val())

    });

    console.log(secoes);



    await api.salvarSecaoToolboxcategorie(this.state.categoriaEditor,secoes).then((res) => {

      console.log(res.data);
      return true;

    }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        return false;

    });

    window.$('div').modal('hide');

    this.getToolboxcategorie();
    this.getAllSections();


    
  }

  componentDidMount(){ 

    this.getToolboxcategorie();
    this.getAllSections();

    window.app.loading(false); 

  }
  
  render() {
  
    return (<>

          <link rel='stylesheet' id='mqueries-style-css' href='/css/principal.css' type='text/css' media='all' />

          <div className="section-inner">
            
            <div className="wrapper">
                
              <div className="section-title" style={{marginBottom:'30px'}}>
                  <h2 style={{color:'#fff'}}>Blockly ToolBox</h2> 

                  <button type="submit" className="btn btn-outline-light" onClick={ () => this.getToolboxcategorie() }>Atualizar <i className="fas fa-sync-alt"></i></button> <button type="submit" className="btn btn-outline-light" onClick={ () => this.novoToolboxcategorie() } >Nova toolbox <i className="fas fa-plus"></i></button>

              </div>

              <table className="table table-dark">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Seções</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>

                {

                  this.state.categorias.map(function(item, i){
                   
                    return(<tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>{item.sections.length}</td>
                      <td>
                        
                        <button type="button" className="btn btn-primary btn-sm" onClick={ () => this.editarToolboxcategorie(item.id) }><i className="fas fa-pencil-alt"></i> Editar</button> 
                        <font> </font>
                        <button type="button" className="btn btn-primary btn-sm" onClick={ () => this.editarSecaoToolboxcategorie(item.id) }><i className="fas fa-pencil-alt"></i> seções</button> 
                        <font> </font>
                        <button onClick={ () => this.removerToolboxcategorie(item.id) } type="button" className="btn btn-danger btn-sm"><i className="far fa-trash-alt"></i> Remover</button>

                      </td>
                    </tr>)

                  }.bind(this))

                }

                </tbody>
              </table>

            </div>

          </div>



 

        <div className="modal  " id="categoriaModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
          <div className="modal-dialog"  role="document">
              <div className="modal-content">
                
              <br/>
              <center><h3 id="editarCategoria">{this.state.tituloModal}</h3></center>

                  <div className="modal-body">
                    
                    <form id="formCadastroCategoria" onSubmit={ (event) => this.saveToolboxcategorie(event) }>
                          
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group">
                                <label>Nome:</label>
                                <input required type="text" name="name" className="form-control"  aria-describedby="emailHelp" /> 
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                                <label >Descrição:</label>
                                <textarea type="text" name="description" className="form-control" aria-describedby="emailHelp"></textarea>
                          </div>
                        </div>

                      </div>


                       <div className="row">

                        <div className="col-md-12">
                          <div className="form-group">
                                <label >Manual XML:</label>
                                <AceEditor
                                  mode="javascript"
                                  ref="aceEditor"
                                  theme="monokai" 
                                  name="aceEditor"
                                  editorProps={{$blockScrolling: true}}
                                  width="auto"
                                  height="200"
                                  showPrintMargin={false}

                                />
                          </div>
                        </div>

                      </div>

                      <textarea hidden type="text" name="manual_xml" id="manual_xml" className="form-control" aria-describedby="emailHelp"></textarea>

                      <button type="submit" className="btn btn-primary" style={{width:'100%'}} >{this.state.botaoModal}</button>

                    </form>

                  </div> 

              </div>
            </div>
          </div>





          <div className="modal" id="secaoCategoriaModal" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" >
          <div className="modal-dialog"  role="document">
              <div className="modal-content">
                
              <br/>
              <center><h3>{this.state.tituloModal}</h3></center>

                  <div className="modal-body">
                    
                    <form id="formCadastroSecaoCategoria" onSubmit={ (event) => this.salvarSecaoToolboxcategorie(event) }>
                          
                      <div className="row">
                        
                      { 

                        this.state.sections.map(function(item, i){
                            
                          var id = "id_c"+item.id;

                          return(

                              <div className="col-md-12" key={item.id} style={{display:"flex",marginBottom:"5px"}}>
                                <input className="checkSelectImport44 checkBoxScene" type="checkbox" id={id} idc={item.id} value={item.id} />
                                <label htmlFor={id} style={{lineHeight:'11px',marginLeft:'5px'}}>{item.name} - {item.description}</label>
                              </div>

                          )

                        })

                      }

                      </div>

                      <button type="submit" className="btn btn-primary" style={{width:'100%'}} >{this.state.botaoModal}</button>

                    </form>

                  </div> 

              </div>
            </div>
          </div>








    </>);

  }


}

export default Blockly_categoria;
