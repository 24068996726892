import React, { Component } from 'react';
import $ from 'jquery';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
//import $ from 'jquery';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import imgAzul from '../assets/images/azul.png';
import imgJSON from '../assets/images/json.png';

import { AiOutlinePushpin, AiFillPushpin } from 'react-icons/ai';
//import { SiSquare } from 'react-icons/si'
import { VscClose } from 'react-icons/vsc'
import { BiWindows } from 'react-icons/bi'
//import Resizable from 're-resizable';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/59228-block-loader.json'
import Modal from 'react-modal';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VerifyUpdate from '../studio/VerifyUpdate';

//ICONS

import { MdClose } from 'react-icons/md';



var cl, s;

// const contentStyle = { background: 'transparent', border: 'none', width: 'auto' };

// const overlayStyle = {};
// const arrowStyle = {}; // style for an svg element

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserwidth + 'px' : "",
		height: window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserheigth + 'px' : "",
	},
};

// const finalSpaceCharacters = [
// 	{
// 		id: 'gary',
// 		name: 'Gary Goodspeed',
// 		thumb: '/images/gary.png'
// 	},
// 	{
// 		id: 'cato',
// 		name: 'Little Cato',
// 		thumb: '/images/cato.png'
// 	},
// 	{
// 		id: 'kvn',
// 		name: 'KVN',
// 		thumb: '/images/kvn.png'
// 	},
// 	{
// 		id: 'mooncake',
// 		name: 'Mooncake',
// 		thumb: '/images/mooncake.png'
// 	},
// 	{
// 		id: 'quinn',
// 		name: 'Quinn Ergon',
// 		thumb: '/images/quinn.png'
// 	}
// ]

const SortableItem = SortableElement(({ value }) => {

	cl = 'rdTab myTab react-draggable react-draggable-dragged'

	if (parseInt(value.id) === parseInt(window.studio_blockly.state.game.fileSelected)) { cl = cl + ' ativo'; }

	if (value.hidden === 1) { s = { display: 'none' }; } else { s = {}; }

	var na = "";
	window.studio_blockly.state.Assets.forEach(function (item, index, array) {

		if (item.file !== null) {

			if (parseInt(item.file.id) === parseInt(value.id)) { na = item; }

		}

	});

	var url_img;

	if (value.type === "asset") {

		if (na.upload !== undefined) { url_img = sessionStorage.getItem('backend') + na.upload.url; } else { url_img = ""; }

		//
	}

	return (
		<li idctype={value.type} idc={value.id} className={cl} style={s}>

			<span onClick={() => window.studio_blockly.preGetFile(value.id)} className="rdTabBeforeTitle"><span className="icon-html5">

				{

					(value.type === "asset") &&

					<div
						style={{
							width: '17px',
							height: '17px',
							backgroundImage: `url(${url_img})`,
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundSize: 'cover',
							marginLeft: '5px',
							marginTop: '-2px'
						}}

						onClick={() => window.studio_blockly.preGetFile(value.id)}  >

					</div>


				}


				{

					(value.type === "scene") &&
					<div
						style={{
							width: '17px',
							height: '17px',
							backgroundImage: `url(${imgAzul})`,
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundSize: 'cover',
							marginLeft: '5px',
							marginTop: '-2px'
						}}

						onClick={() => window.studio_blockly.preGetFile(value.id)}  >

					</div>

				}

				{

					(value.type === "json") &&

					<div
						style={{
							width: '17px',
							height: '17px',
							backgroundImage: `url(${imgJSON})`,
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundSize: 'cover',
							marginLeft: '5px',
							marginTop: '-2px'
						}}

						onClick={() => window.studio_blockly.preGetFile(value.id)}  >

					</div>



				}

			</span></span>
			<span onClick={() => window.studio_blockly.preGetFile(value.id)} className="rdTabTitle myTabTitle" >{value.name}</span>
			<span onClick={() => window.studio_blockly.preGetFile(value.id)} className="rdTabAfterTitle"><span></span></span>
			<span style={{ zIndex: '99999' }} className="rdTabCloseIcon tabCloseIcon" onClick={() => window.studio_blockly.ocultarFile(value.id)}>×</span>
			<span onClick={() => window.studio_blockly.preGetFile(value.id)} className="rdTabBefore myTabBefore"></span>
			<span onClick={() => window.studio_blockly.preGetFile(value.id)} className="rdTabAfter myTabAfter">  </span>

		</li>
	)

});

const SortableList = SortableContainer(({ items, removeFile }) => {



	return (
		<>
			<ul className="fileListEs">{items.map((value, index) => {


				for (let index2 = 0; index2 < window.studio_blockly.state.Assets.length; index2++) {

					if (window.studio_blockly.state.Assets[index2].file.id === value.id) {

						//				console.log(window.studio_blockly.state.Assets[index2]);
						if (window.studio_blockly.state.Assets[index2].type === 3) {

							return (<React.Fragment key={`item-${index}`}></React.Fragment>);

						}

					}

				}

				return (<SortableItem onClick={() => console.log('criou')} key={`item-${index}`} index={index} value={value} />)

			})}</ul>


		</>
	);

});




class Files extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isActiveOpacity: true,
			isOpenModal: false,
			listOpenTabs: false
		}

		window.tablefiles = this;


	}


	onDragEnd = result => {

		if (result.destination !== null) {
			window.studio_blockly.onSortFileEndVertical(result)
		}

		//console.log("RESULT: ", result)
	}

	render() {
		var url = sessionStorage.getItem('backend') + '/games/generatecode/' + window.studio.props.match.params.game + '/';
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice'
			}
		};

		var listFiles = {}
		if (window.studio_blockly !== undefined) {
			listFiles = window.studio_blockly.state.filesList


		}


		return (
			<div className="rdTabWrapper myWrapper">
				<VerifyUpdate {...this.props} />
				<select value={window.studio_blockly.state.game.toolBoxSelected} id="selectToolBox" onChange={(event) => {
					window.studio_blockly.preGetToolBox(event.target.value);
					//	console.log("event.target.value: ", event.target.value)
				}}>
					{window.studio_blockly.state.toolBoxList.map(window.studio_blockly.renderOption)}
				</select>


				{
					window.translateBlockly !== undefined &&
					window.translateBlockly === "translate(99px, 10px)" && (

						<div className="container_list_files_open " >
							<DragDropContext onDragEnd={this.onDragEnd}>
								<Droppable droppableId="characters" direction="vertical">
									{(provided) => (
										<ul className="characters fileListEs" {...provided.droppableProps} ref={provided.innerRef}>
											{
												listFiles.map((value, index) => {



													//	console.log("LISTFILE: ", value);

													cl = 'item_menu_vertical_tabs'

													if (parseInt(value.id) === parseInt(window.studio_blockly.state.game.fileSelected)) { cl = cl + ' ativo '; }


													var na = "";

													window.studio_blockly.state.Assets.forEach(function (item, index, array) {

														if (item.file !== null) {

															if (parseInt(item.file.id) === parseInt(value.id)) { na = item; }

														}

													});

													var url_img;

													if (value.type === "asset") {

														if (na.upload !== undefined) { url_img = sessionStorage.getItem('backend') + na.upload.url; } else { url_img = ""; }

														//
													}


													return (
														<Draggable
															key={(value.name !== undefined) && (value.name !== '') && (value.name !== null) ? value.name : index}
															draggableId={
																(value.name !== undefined) && (value.name !== '') && (value.name !== null) ? value.name : index}
															index={index}>
															{(provided) => (


																<li
																	idctype={value.type}
																	idc={value.id}
																	className={"rdTab  item_menu_vertical_tabs " + (parseInt(value.id) === parseInt(window.studio_blockly.state.game.fileSelected) ? " ativo " : "")}
																	hidden={value.hidden === 1 ? true : false}
																	ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

																	<div className="container_icon_menu_vertical" onClick={() => window.studio_blockly.preGetFile(value.id, value.type)}>

																		<span className="icon-html5">

																			{

																				(value.type === "asset") &&

																				<div
																					className="img_icon_menu_vertical"
																					style={{
																						backgroundImage: `url(${url_img})`,
																					}}

																					onClick={() => window.studio_blockly.preGetFile(value.id, value.type)}  >

																				</div>


																			}


																			{

																				(value.type === "scene") &&
																				<div
																					className="img_icon_menu_vertical"
																					style={{
																						backgroundImage: `url(${imgAzul})`,
																					}}

																					onClick={() => window.studio_blockly.preGetFile(value.id, value.type)}  >

																				</div>

																			}

																			{

																				(value.type === "json") &&

																				<div
																					className="img_icon_menu_vertical"
																					style={{

																						backgroundImage: `url(${imgJSON})`,

																					}}

																					onClick={() => window.studio_blockly.preGetFile(value.id, value.type)}  >

																				</div>



																			}

																		</span>

																		<span title={value.name} className="name_aba_hover_menu_vertical">{value.name}</span>

																	</div>

																	<button className="btn_close_menu_vertical" onClick={() => window.studio_blockly.ocultarFile(value.id)}><MdClose /></button>

																</li>


															)}
														</Draggable>
													);
												})}
											{provided.placeholder}
										</ul>
									)}
								</Droppable>
							</DragDropContext>
						</div>

					)
				}

				{
					window.translateBlockly !== undefined &&
					window.translateBlockly === "translate(99px, 0px)" && (
						<SortableList
							distance={10}
							lockAxis={localStorage.getItem("layout") === "vertical" ? "xy" : "x"} axis={localStorage.getItem("layout") === "vertical" ? "xy" : "x"}
							helperClass="rdTab myTab"
							onSortEnd={window.studio_blockly.onSortFileEnd}
							items={window.studio_blockly.state.filesList}

						/>
					)
				}



				<div style={{
					display: 'flex', alignItems: 'center', width: 'calc(100% - 150px)', justifyContent: 'flex-end'
				}}>



					<Popup
						trigger={
							<button
								onClick={() => {
									this.setState({ isOpenModal: true })
								}}
								className={"btn_modal_body_blocks"}>
								{<BiWindows />}
							</button>
						}
						position="bottom right"
						on="hover"
						closeOnDocumentClick={true}
						onClose={() => {
							//this.setState({ isOpen: false })
						}}
						mouseLeaveDelay={0}
						mouseEnterDelay={0}
						contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
						arrow={true}
					>
						<span className="text_poup_tippy">Abrir o jogo em janela flutuante.</span>
					</Popup>





					<Modal
						ariaHideApp={!this.state.isOpenModal}
						isOpen={this.state.isOpenModal}
						onAfterClose={() => {
							this.setState({ isOpenModal: false });
							this.props.onUpdateModalIframe(false);
						}}
						onAfterOpen={() => {
							this.setState({ isOpenModal: true });

						}}
						onRequestClose={() => {
							this.setState({ isOpenModal: false });

						}}
						style={customStyles}
						contentLabel="Example Modal"
					>


						<button

							onClick={() => {
								this.setState({ isOpenModal: false });
							}}
							className={"btn_close_modal_body_blocks"}>
							{<VscClose />}
						</button>
						<div
							id="content_iframe_Phaser"

							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center'
							}}>


							<iframe
								id="IframePhaser2"
								title="phaserframe"
								src={url}
								src_old={url}
								frameBorder="0"
								style={{
									boxShadow: '0px 0px 11px -4px #000',
									width: window.studio_blockly.state.game.phaserwidth + 'px',
									height: window.studio_blockly.state.game.phaserheigth + 'px',
									background: '#fff'
								}} ></iframe>

							<div hidden className="window_loading_iframe">
								<Lottie
									options={defaultOptions}
									height={200}
									width={200}
									isClickToPauseDisabled={true}
									autoplay={true}
								/>
							</div>



						</div>





					</Modal>


					<Popup
						trigger={
							<button
								onClick={() => {
									if (this.state.isActiveOpacity) {
										this.setState({ isActiveOpacity: false });
										$(".container_pageStudio_blocks_menu").css('opacity', '1');

									} else {
										this.setState({ isActiveOpacity: true });
										$(".container_pageStudio_blocks_menu").css('opacity', '');
									}

								}}
								className={"btn_fixed_body_blocks " + (this.state.isActiveOpacity === false ? " active " : "")}>
								{this.state.isActiveOpacity ? <AiOutlinePushpin /> : <AiFillPushpin />}
							</button>
						}
						position="bottom right"
						on="hover"
						closeOnDocumentClick={true}
						onClose={() => {
							//this.setState({ isOpen: false })
						}}
						mouseLeaveDelay={0}
						mouseEnterDelay={0}
						contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
						arrow={true}

					>
						<span className="text_poup_tippy">{this.state.isActiveOpacity ? "Desativar " : "Ativar "} opacidade automatica da área de trabalho.</span>
					</Popup>

				</div>
				<div style={{ cursor: 'pointer' }} className="maisFile" onClick={() => window.studio_blockly.newFileByName()}>+</div>

			</div>
		);

	}


}

export default Files;


/*
				<Popup
														trigger={

															<li
																onClick={() => window.studio_blockly.preGetFile(value.id)}
																className={"item_menu_vertical_tabs " + (parseInt(value.id) === parseInt(window.studio_blockly.state.game.fileSelected) ? " ativo " : "")}
																hidden={value.hidden === 1 ? true : false} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																<div className="container_icon_menu_vertical">
																	<span className="icon-html5">

																		{

																			(value.type === "asset") &&

																			<div
																				className="img_icon_menu_vertical"
																				style={{
																					backgroundImage: `url(${url_img})`,
																				}}

																				onClick={() => window.studio_blockly.preGetFile(value.id)}  >

																			</div>


																		}


																		{

																			(value.type === "scene") &&
																			<div
																				className="img_icon_menu_vertical"
																				style={{
																					backgroundImage: `url(${imgAzul})`,
																				}}

																				onClick={() => window.studio_blockly.preGetFile(value.id)}  >

																			</div>

																		}

																		{

																			(value.type === "json") &&

																			<div
																				className="img_icon_menu_vertical"
																				style={{

																					backgroundImage: `url(${imgJSON})`,

																				}}

																				onClick={() => window.studio_blockly.preGetFile(value.id)}  >

																			</div>



																		}

																	</span>
																</div>

																<button className="btn_close_menu_vertical" onClick={() => window.studio_blockly.ocultarFile(value.id)}><MdClose /></button>


															</li>

														}
														position="right center"
														on="hover"
														closeOnDocumentClick={true}
														onClose={() => {
															//this.setState({ isOpen: false })
														}}
														mouseLeaveDelay={0}
														mouseEnterDelay={0}
														contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
														arrow={true}
													>
														<span className="text_poup_tippy">{value.name}</span>
													</Popup>
													*/