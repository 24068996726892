import React, { Component } from 'react';
import $ from 'jquery';

//import './assets/login-v3.default.css';
//import './assets/vendors.bundle.css';
import api from './../Api';
//import Sortable from 'sortablejs';
import swal from 'sweetalert';

//import Swal from 'sweetalert2';

//import Login from './Login';
//import Head from './Head';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'

import { FiSidebar } from 'react-icons/fi'
//import { RiLayoutBottomLine } from 'react-icons/ri'
class Configuration extends Component {

	constructor(props) {

		super(props);

		this.state = { mostrar: false, isOpenModal: false, boxDimensions: false };
		this.resetConfiguration = this.resetConfiguration.bind(this);
		this.saveConfiguration = this.saveConfiguration.bind(this);
		window.gameConfigurationModal = this;

	}

	componentDidMount() {

		this.setState({ mostrar: true, boxDimensions: sessionStorage.getItem('box_cursor_dimensions') !== undefined ? sessionStorage.getItem('box_cursor_dimensions') : false });

	}

	resetConfiguration() {

		this.setState({ mostrar: false, isOpenModal: true });
		setTimeout(function () { this.setState({ mostrar: true }); }.bind(this), 10);

		//window.$("#configurationModal").modal();

	}

	duplicate(){

        swal({
            title: "Deseja duplicar o projeto?",
            text: "",
            icon: "warning",
            dangerMode: true, 
            buttons: ["Não cancelar", "Sim"],
        }).then(willDelete => {
            
            if (willDelete) {
            
				api.duplicateProject(window.app.state.dataUser.user,window.studio_blockly.state.game.id,1,window.app.state.dataUser.email,window.studio_blockly.state.game.name+' - copia').then(async (res) => {

					if(res.data.id !== undefined){

						window.location = "/studio/"+res.data.id;

					}

				}).catch((error) => {});

            }
            
        });

    }

	//para salvar as novas configurações
	saveConfiguration(event) {

		event.preventDefault();

		var dados = { name: $('#nameGameInput').val(), description: $("#descriptionGame").val(), defaultEditor: $("#defaultEditor").val(), phaserwidth: $("#exampleInputEmail4").val(), phaserheigth: $("#exampleInputEmail3").val() }

		$("#divIframePhaser").css('width', $("#exampleInputEmail4").val() + 'px');
		$("#divIframePhaser").css('height', (parseInt($("#exampleInputEmail3").val()) + 30), 'px');

		console.log('saveSettings');
		console.log(dados);

		api.saveSettings(window.studio.props.match.params.game, dados).then((res) => {

			toast.success('As configurações foram salvas.', { position: "bottom-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: false });
			window.$('div').modal('hide');
			window.studio_blockly.setState({ game: res.data });
			window.phaser.setState({ a: 1 });
			this.setState({ isOpenModal: false })
		}).catch(error => {

			toast.error('Não foi possivel salvas as configurações', { position: "bottom-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: false });

			console.log(error.data);
			console.log(error);
			console.log(error.response);

		});


	}

	async changeLayout(value) {
		await localStorage.setItem("layout", value);
		/*
		
				if (localStorage.getItem("layout") === "vertical") {
					$(".blocklyToolboxDiv").addClass("blocklyToolboxDiv_vertical");
					$(".blocklyToolboxDiv").removeClass("blocklyToolboxDiv_horizontal");
		
					$(".container_pageStudio_blocks_menu").addClass("container_pageStudio_blocks_menu_vertical");
					$(".container_pageStudio_blocks_menu").removeClass("container_pageStudio_blocks_menu_horizontal");
		
					$(".blocklySvg").addClass("blocklySvg_vertical");
					$(".blocklySvg").removeClass("blocklySvg_horizontal");
		
					$(".btn_pin_fixed_Phaser").addClass("btn_pin_fixed_Phaser_vertical");
					$(".btn_pin_fixed_Phaser").removeClass("btn_pin_fixed_Phaser_horizontal");
		
		
					$(".blocklyFlyout").addClass("blocklyFlyout_vertical");
					$(".blocklyFlyout").removeClass("blocklyFlyout_horizontal");
		
					$("#selectToolBox").addClass("selectToolBox_vertical");
					$("#selectToolBox").removeClass("selectToolBox_horizontal");
		
					$(".rdTabWrapper ul").addClass("rdTabWrapper_vertical");
					$(".rdTabWrapper ul").removeClass("rdTabWrapper_horizontal");
		
					$("#content_iframe_Phaser").addClass("content_iframe_Phaser_vertical");
					$("#content_iframe_Phaser").removeClass("content_iframe_Phaser_horizontal");
		
					$(".fileListEs").addClass("fileListEs_vertical");
					$(".fileListEs").removeClass("fileListEs_horizontal");
		
					$(".injectionDiv").addClass("injectionDiv_vertical");
					$(".injectionDiv").removeClass("injectionDiv_horizontal");
		
					$(".myWrapper").addClass("myWrapper_vertical");
					$(".myWrapper").removeClass("myWrapper_horizontal");
		
					$("#selectToolBox").addClass("selectToolBox_vertical");
					$("#selectToolBox").removeClass("selectToolBox_horizontal");
		
		
					$(".content_infos_dimensions").addClass("content_infos_dimensions_vertical");
					$(".content_infos_dimensions").removeClass("content_infos_dimensions_horizontal");
		
					$(".react-draggable-dragged").addClass("react-draggable_vertical");
					$(".react-draggable-dragged").removeClass("react-draggable_horizontal");
		
		
					window.translateBlockly = "translate(0px, 200px)"
				}
		
				if (localStorage.getItem("layout") === "horizontal") {
		
		
					$(".blocklyToolboxDiv").removeClass("blocklyToolboxDiv_vertical");
		
		
					$(".container_pageStudio_blocks_menu").removeClass("container_pageStudio_blocks_menu_vertical");
		
		
					$(".blocklySvg").removeClass("blocklySvg_horizontal");
		
		
					$(".btn_pin_fixed_Phaser").removeClass("btn_pin_fixed_Phaser_vertical");
		
		
		
					$(".blocklyFlyout").removeClass("blocklyFlyout_vertical");
		
		
					$("#selectToolBox").removeClass("selectToolBox_vertical");
		
		
					$(".rdTabWrapper ul").removeClass("rdTabWrapper_vertical");
		
		
					$("#content_iframe_Phaser").removeClass("content_iframe_Phaser_vertical");
		
		
		
					$(".fileListEs").removeClass("fileListEs_vertical");
		
		
					$(".injectionDiv").removeClass("injectionDiv_vertical");
		
		
					$(".myWrapper").removeClass("myWrapper_vertical");
		
		
					$("#selectToolBox").removeClass("selectToolBox_vertical");
		
		
					$(".content_infos_dimensions").removeClass("content_infos_dimensions_vertical");
		
		
		
					$(".react-draggable-dragged").removeClass("react-draggable_vertical");
		
		
					window.translateBlockly = "translate(150px, 0px)"
				}
		
				*/
		window.location.reload();
	}

	render() {

		return (<>

			<Popup
				open={this.state.isOpenModal}
				closeOnDocumentClick
				onOpen={() => { }}
				onClose={() => { this.setState({ isOpenModal: false }); }}
				modal>
				<div className="title_modal_uploadAsset">
					<h1>Configurações do projeto</h1>
				</div>

				{(window.studio_blockly.state.game.name !== undefined && this.state.mostrar === true) &&

					<form id="configurationForm" onSubmit={(event) => {
						this.saveConfiguration(event)




					}}>

						<div className="modal-body">

							<div className="form-group">
								<label htmlFor="exampleInputEmail1" className="label_input_form_config">Nome:</label>
								<input type="text" id="nameGameInput" name="name" defaultValue={window.studio_blockly.state.game.name} className="form-control label_input_form_config" aria-describedby="emailHelp" />
							</div>

							<div className="form-group" hidden>
								<label htmlFor="exampleInputEmail1 ">Editor padrão:</label>


								<select id="defaultEditor" defaultValue={window.studio_blockly.state.game.defaultEditor.toString()} className="form-control">

									{

										window.defaultEditorOptions.map(function (item, i) {

											return <option key={i} value={i} >{item}</option>

										})

									}

								</select>
							</div>

							<div className="form-group">
								<label htmlFor="exampleInputEmail1" className="label_input_form_config">Descrição:</label>
								<textarea type="text" name="name" className="form-control label_input_form_config" id="descriptionGame" aria-describedby="emailHelp" defaultValue={window.studio_blockly.state.game.description}></textarea>
							</div>

							<div className="row">

								<div className="col-md-4">
									<div className="form-group">
										<label htmlFor="exampleInputEmail3" className="label_input_form_config">Altura:</label>
										<input required defaultValue={window.studio_blockly.state.game.phaserheigth} type="text" name="name" className="form-control label_input_form_config" id="exampleInputEmail3" aria-describedby="emailHelp" />
									</div>
								</div>

								<div className="col-md-4">
									<div className="form-group">
										<label htmlFor="exampleInputEmail4" className="label_input_form_config">Largura:</label>
										<input required defaultValue={window.studio_blockly.state.game.phaserwidth} type="text" name="name" className="form-control label_input_form_config" id="exampleInputEmail4" aria-describedby="emailHelp" />
									</div>
								</div>





							</div>
							<div className="row">
								<div className="col-md-12">
									<label className="label_input_form_config">Layout</label>
									<button className={"btn_layout_config " + (localStorage.getItem("layout") === "vertical" ? " active " : "") } onClick={() => this.changeLayout("vertical")}><FiSidebar /></button>
									<button className={"btn_layout_config horizontal " + (localStorage.getItem("layout") === "horizontal" || localStorage.getItem("layout") === undefined || localStorage.getItem("layout") === null  ? " active " : "") } onClick={() => this.changeLayout("horizontal")}><FiSidebar /></button>
								</div>
							</div>

							<div className="row mt-4" hidden>
								<div className="col-md-12">
									<label className="label_input_form_config">Configurações do cenário</label>

								</div>



								<div className="col-md-12 ">
									<div className="form-group">
										<div className="card" style={{ width: '100%' }}>
											<input
												onFocus={(e) => {
												//	console.log("Focus: ", !e.target.checked)
													this.setState({ mostrar: true, boxDimensions: !e.target.checked });
												}}
												className="checkSelectImport checkBoxScene custom"
												type="checkbox"
												id="box_cursor_dimensions"
												checked={this.state.boxDimensions}
											/>
											<label style={{ textAlign: 'center' }} htmlFor="box_cursor_dimensions" className="text_label_input_group">Caixa de Dimensões acompanhando o cursor</label>
										</div>
									</div>
								</div>
							</div>
						</div>




						<div className="modal-footer">
							
							<button type="button" className="btn btn-secondary btn_form_config" onClick={() => this.duplicate()}>Duplicar projeto</button>
							<button onClick={() => { window.studio.deleteGame(); this.setState({ isOpenModal: false }); }} type="button" className="btn btn-danger btn_form_config" data-dismiss="modal" style={{ float: 'left' }}>Remover game</button>

							<button type="button" onClick={() => this.setState({ isOpenModal: false })} className="btn btn-secondary btn_form_config" data-dismiss="modal">Cancelar</button>
							<button type="submit" className="btn btn-primary btn_form_config">Salvar alterações</button>
						</div>

					</form>

				}


			</Popup>



		</>)

	}


}

export default Configuration;

/*


			<div className="modal fade" id="configurationModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">

						<div className="modal-header">
							<h5 className="modal-title">Configurações do projeto2</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						{(window.studio_blockly.state.game.name !== undefined && this.state.mostrar === true) &&

							<form id="configurationForm" onSubmit={(event) => this.saveConfiguration(event)}>

								<div className="modal-body">

									<div className="form-group">
										<label htmlFor="exampleInputEmail1">Nome:</label>
										<input type="text" id="nameGameInput" name="name" defaultValue={window.studio_blockly.state.game.name} className="form-control" aria-describedby="emailHelp" />
									</div>

									<div className="form-group" hidden>
										<label htmlFor="exampleInputEmail1">Editor padrão:</label>


										<select id="defaultEditor" defaultValue={window.studio_blockly.state.game.defaultEditor.toString()} className="form-control">

											{

												window.defaultEditorOptions.map(function (item, i) {

													return <option key={i} value={i} >{item}</option>

												})

											}

										</select>
									</div>

									<div className="form-group">
										<label htmlFor="exampleInputEmail1">Descrição:</label>
										<textarea type="text" name="name" className="form-control" id="descriptionGame" aria-describedby="emailHelp" defaultValue={window.studio_blockly.state.game.description}></textarea>
									</div>

									<div className="row">

										<div className="col-md-4">
											<div className="form-group">
												<label htmlFor="exampleInputEmail3">Altura:</label>
												<input required defaultValue={window.studio_blockly.state.game.phaserheigth} type="text" name="name" className="form-control" id="exampleInputEmail3" aria-describedby="emailHelp" />
											</div>
										</div>

										<div className="col-md-4">
											<div className="form-group">
												<label htmlFor="exampleInputEmail4">Largura:</label>
												<input required defaultValue={window.studio_blockly.state.game.phaserwidth} type="text" name="name" className="form-control" id="exampleInputEmail4" aria-describedby="emailHelp" />
											</div>
										</div>

										<div className="col-md-4">
											<div className="form-group">
												<label htmlFor="exampleInputEmail5">Tipo de física:</label>
												<select defaultValue={window.studio_blockly.state.game.physic} type="text" name="name" required="" className="form-control" id="exampleInputEmail5" aria-describedby="emailHelp">

													<option value="arcade">Arcade</option>

												</select>
											</div>
										</div>

									</div>

								</div>

								<div className="modal-footer">

									<button onClick={() => window.studio.deleteGame()} type="button" className="btn btn-danger" data-dismiss="modal" style={{ float: 'left' }}>Remover game</button>

									<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
									<button type="submit" className="btn btn-primary">Salvar alterações</button>
								</div>

							</form>

						}

					</div>
				</div>
			</div>

			*/