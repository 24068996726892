import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'

class StoreModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpenModal: false
        }

        window.StoreModal = this;
    }

    openModal() {
        this.setState({ isOpenModal: true })
    }

    componentDidMount() {
        console.log("O Componente foi montado !")
    }


    render() {
        return (

            <Popup
                open={this.state.isOpenModal}
                closeOnDocumentClick
                onOpen={() => { }}
                onClose={() => { this.setState({ isOpenModal: false }); }}
                modal>

                <div className="title_modal_uploadAsset">
                    <h1>Gerenciador de Arquivos Compartilhados</h1>
                </div>

                <div className="modal-content" style={{ maxHeight: '90vh' }}>

                    <style>{'.fecharAsset{ cursor:pointer; position: absolute; left: calc(100% - 55px); z-index: 9999; font-size: 29px; top: 62px; color: #fff; }'}</style>
                    <button className="close_modal_btn store"  onClick={() => this.setState({ isOpenModal: false })}>
                        <i className="fas fa-times"></i>
                    </button>
                    <iframe title="store" className="iframeAsset" src={window.urlstore} frameBorder="0"></iframe>

                </div>
            </Popup>
        )
    }
}

export default StoreModal;