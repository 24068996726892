import React, { Component } from 'react';
import $ from 'jquery';

import { toast } from 'react-toastify';

import api from './../Api';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'

class ImportAsseAsset extends Component {

	constructor(props) {

		super(props);
		this.state = { SortablePane: true, order: [], file: {}, asset: {}, isOpenModal: false };
		this.openModalImportAsseAsset = this.openModalImportAsseAsset.bind(this);

		window.ImportAsseAsset = this;

	}

	openModalImportAsseAsset(id) {

		window.$('#ImportAssetAssetModal').modal();

		window.$('.checkSelectImportAssetAsset').prop('checked', false);

		this.setState({ a: 1, editorId: id, isOpenModal: true });


		//pego o asset
		var asset = window.studio_blockly.state.Assets;
		var fileid;
		asset.forEach(function (item, index, array) {

			if (parseInt(item.id) === parseInt(id)) {

				this.setState({ asset: item });
				fileid = item.file.id;

			}

		}.bind(this));

		console.log(fileid);

		var file = window.studio_blockly.state.filesList;
		setTimeout(function () {

			file.forEach(function (item, index, array) {

				console.log(item.id + ' === ' + fileid);

				if (parseInt(item.id) === parseInt(fileid)) {
				//	console.log("Item: ", item)
					//	this.setState({ file: item });
					$.each(item.importAssets, function (index, value) {

						console.log('checkSelectImportAssetAsset[idc=' + value.id + ']');
						$('.checkSelectImportAssetAsset[idc=' + value.id + ']').prop('checked', true);
						console.log('checou ' + value.id);

					});

					return true;

				}

			});

		}, 10);

	}

	async salvaImportAsseAsset() {

		var assets = [];
		$(".checkSelectImportAssetAsset:checked").each(function () {

			assets.push($(this).val());

		});

		await api.updateFileImportAsset(this.state.asset.file.id, assets).then((res) => {

			console.log(res.data);

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

		});

		window.studio_blockly.getAllFiles();

		this.setState({ isOpenModal: false });

		window.$('#ImportAssetAssetModal').modal('hide');
		toast.success('As alterações foram salvas.', { position: "bottom-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: false });


		var obj = {};

		obj.game = window.studio_blockly.state.game.id;
		obj.user = window.app.state.userId;



		//para emetir para o resto do pessoal
		window.emitir('syncScenes', obj);


	}

	render() {

		var assets = {};
		window.defaultAssetsType.map((value, index) => {

			assets[value.name] = [];
			window.studio_blockly.state.Assets.map((value2, index2) => {

				if (parseInt(value2.id) !== parseInt(this.state.editorId)) { if (parseInt(value2.type) === parseInt(value.id)) { assets[value.name].push(value2); } }

				return "";

			});

			return "";

		});

		return (

			<Popup
				open={this.state.isOpenModal}
				closeOnDocumentClick
				onOpen={() => { }}
				onClose={() => { this.setState({ isOpenModal: false }); }}
				modal>
				<div className="title_modal_uploadAsset">
					<h1>Quais assets deseja vincular ao asset <span style={{ color: '#FB5607', textDecoration: 'underline' }}>{this.state.asset.name}</span> ?  </h1>
				</div>

				<div className="modal-body">

				



					{

						window.defaultAssetsType.map(function (value, i) {

							if (value.id === "3") { return (<React.Fragment key={value.id}></React.Fragment>); }

							return (
								<div key={value.id} className="col-md-12" style={{
									marginBottom: '12px', maxHeight: '30vh',
									overflowX: 'hidden',
									overflowY: 'auto', display: 'flex', flexWrap: 'wrap', gap: '1%'
								}}>

									<h5 className="title_label_input_group" >{value.name3}</h5>
									{

										(assets[value.name].length === 0) &&
										<div className="col-md-12 text_label_input_group">Nenhum {value.name3} foi encontrado.</div>

									}
									{
										assets[value.name].map(function (value2, i2) {

											var id = value.name + value.id + value2.id + 'nrid';
											return (

												<div key={value2.id} className="card">
													<input className="checkSelectImportAssetAsset checkBoxScene custom" type="checkbox" id={id} idc={value2.id} value={value2.id} />
													<label htmlFor={id} className="text_label_input_group">{value2.name}</label>
												</div>

											)

										})

									}


								</div>)

						})

					}

				</div>

				<div className="modal-footer">

					<button onClick={() => this.setState({ isOpenModal: false })} type="button" className="btn btn-secondary btn_form_config" data-dismiss="modal">Cancelar</button>
					<button type="submit" className="btn btn-primary btn_form_config" onClick={() => this.salvaImportAsseAsset()} >Salvar</button>

				</div>

			</Popup>

		)

	}


}

export default ImportAsseAsset;