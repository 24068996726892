import React, { Component } from 'react';
import{ Link } from 'react-router-dom';

class Configuracao extends Component {
  
  constructor(props){

    super(props); 

    window.Configuracao = this;
    
  }

  componentDidMount(){

  	window.app.loading(false);

  }

    render() {
  
    return (<>
 

        <div className="section-inner">
            
            <div className="wrapper">
                
              <div className="section-title">
                  <h2 style={{color:'#fff'}}>Administração</h2>
                  <div className="seperator size-small"><span></span></div> 
              </div>
                
              <div className="">
                
              <center>
                <div className="row">

                  <div className="col-md-2">
                    <Link to="/usuarios">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                        <i className="fas fa-users fa-4x xone"></i>
                          <h4><strong style={{color:"#fff"}}>Usuários</strong></h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-2">
                    <Link to="/games">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                            <i className="fas fa-gamepad fa-4x xone"></i>
                            <h4><strong style={{color:"#fff"}}>Games</strong></h4>
                      </div>
                    </Link>
                  </div> 

                  <div className="col-md-2">
                    <Link to="/assets">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                            <i className="fas fa-archive fa-4x xone"></i>
                            <h4><strong style={{color:"#fff"}}>Assets</strong></h4>
                      </div>
                    </Link>
                  </div>


                  <div className="col-md-2">
                    <Link to="/bl_categoria">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                            <i className="fas fa-shapes fa-4x xone"></i>
                            <h4><strong style={{color:"#fff"}}>Blockly ToolBox</strong></h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-2">
                    <Link to="/bl_secao">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                            <i className="fas fa-shapes fa-4x xone"></i>
                            <h4><strong style={{color:"#fff"}}>Blockly Categoria</strong></h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-2">
                    <Link to="/bl_blocks">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                            <i className="fas fa-shapes fa-4x xone"></i>
                            <h4><strong style={{color:"#fff"}}>Blockly blocks</strong></h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-2">
                    <Link to="/help">
                      <div className="hvr-grow" style={{cursor:'pointer'}}>
                            <i className="fas fa-people-carry fa-4x xone"></i>
                            <h4><strong style={{color:"#fff"}}>Helpers</strong></h4>
                      </div>
                    </Link>
                  </div>


 
                </div>
              </center>


              </div>
                
      </div> 
  
     </div>


    </>);

  }



}

export default Configuracao;