import React, { Component } from 'react';
//import Tooltip from 'rc-tooltip';
import { SortablePane, Pane } from 'react-sortable-pane';

import api from './../Api';

import { FaEye, FaEyeSlash, FaTrash, FaFileImport } from 'react-icons/fa'
//import { IoMdLock, IoMdUnlock } from 'react-icons/io'
import { MdDragHandle } from 'react-icons/md'
import { BiLink, BiUnlink } from 'react-icons/bi'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export const paneStyle = { height: '20px' };
export const textStyle = { fontSize: '32px', fontWeight: 'bold', color: '#aaa' };

class FileEdit extends Component {

  constructor(props) {

    super(props);
    this.state = { SortablePane: true, order: [] };
    window.files_a = this;

  }

  async updateOrder(order) {


    //this.state.order.forEach(function (item, index, array){

    //window.studio_blockly.state.filesList.forEach(function (item2,index2,array2){

    //if(parseInt(item2.id) === parseInt(item)){ files.push(item2); }

    //});

    //});

    //window.studio_blockly.setState({filesList:files});
    //window.studio_blockly.updateSequence(undefined,1);



    order.forEach(function (item, index, array) {


      api.changeOrderFileList(item, index).then((res) => {

        //if(this.state.log)
        console.log(res.data);


      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        return false;

      });


    });









  }

  render() {


    var panes = [];

    window.studio_blockly.state.filesList.map((value, i) => {

      if (window.studio_blockly.state.game.advanced) { panes.push(value); }
      if (!window.studio_blockly.state.game.advanced) { if (value.type === "scene") { panes.push(value); } }

      return "";

    });

    var cp1, cp2;

    if (window.studio_blockly.state.game.advanced === 1) {

      cp1 = "col-md-3";
      cp2 = "col-md-2";

    } else {

      cp1 = "col-md-12";
      cp2 = "col-md-2 hidden_div";

    }

    return (<>

      <div className="row rowTitlebaixo">

        <div className="col-md-2"></div>
        <div className={cp1}>Key</div>
        <div className="col-md-3">Status</div>
        <div className={cp2}>Editor</div>
        <div className="col-md-2"><center>Ações</center></div>

      </div>

      {(this.state.SortablePane) &&

        <SortablePane
          disableEffect={true}
          onOrderChange={order => { this.updateOrder(order) }}
          dragHandleClassName="movedor"
          direction="vertical"
          margin={20}
          onDragStop={order => { /*this.updateOrder(order); */ }}  >

          {

            panes.map(function (value, i) {

              //<i className="fas fa-arrows-alt movedor" style={{ marginRight: '10px' }}></i>
              return (

                <Pane
                  key={value.id}
                  className="__resizable_base__"

                  defaultSize={{ width: '100%', height: '60px' }}
                >

                  <div style={{ display: 'flex', alignItems: 'center' }}>

                    <div className="container_item_fileEdit " idc={value.id}>

                      <div className="content_left_item_fileEdit">



                        <Popup
                          trigger={
                            <button onClick={() => {
                              if (value.hidden !== 1) {
                                window.studio_blockly.ocultarFile(value.id)
                              } else {
                                window.studio_blockly.desocultarFile(value.id)
                              }
                            }} className="btn_eye_fileEdit" style={{ color: value.hidden === 1 ? "#000" : "" }} >{value.hidden !== 1 ? <FaEye /> : <FaEyeSlash />}</button>

                          }
                          position="bottom right"
                          on="hover"
                          closeOnDocumentClick={true}
                          onClose={() => {
                            //this.setState({ isOpen: false })
                          }}
                          mouseLeaveDelay={0}
                          mouseEnterDelay={0}
                          contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                          arrow={true}

                        >
                          <span className="text_poup_tippy">{value.hidden === 0 ? "Ocultar a" : "Exibir a "} cena.</span>
                        </Popup>



                        <Popup
                          trigger={

                            <input onKeyUp={(event) => window.studio_blockly.updateNameFile(value.id, event.target.value)} file={value.id} className="input_name_fileEdit" defaultValue={value.name} />

                          }
                          position="bottom right"
                          on="hover"
                          closeOnDocumentClick={true}
                          onClose={() => {
                            //this.setState({ isOpen: false })
                          }}
                          mouseLeaveDelay={0}
                          mouseEnterDelay={0}
                          contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                          arrow={true}

                        >
                          <span className="text_poup_tippy">Renomear.</span>
                        </Popup>


                      </div>

                      <div className="content_right_item_fileEdit">


                        <div className="content_group_btn_red_file_edit">
                          {(parseInt(value.editor) === 0) &&
                            <Popup
                              trigger={
                                <button onClick={() => window.importassetscene.openModalImportAssetScene(value.id)}><FaFileImport /></button>
                              }
                              position="bottom right"
                              on="hover"
                              closeOnDocumentClick={true}
                              onClose={() => {
                                //this.setState({ isOpen: false })
                              }}
                              mouseLeaveDelay={0}
                              mouseEnterDelay={0}
                              contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                              arrow={true}

                            >
                              <span className="text_poup_tippy">Vincular.</span>
                            </Popup>




                          }

                          <Popup
                            trigger={
                              <button onClick={() => window.studio_blockly.removeFile(value.id)}><FaTrash /></button>
                            }
                            position="bottom right"
                            on="hover"
                            closeOnDocumentClick={true}
                            onClose={() => {
                              //this.setState({ isOpen: false })
                            }}
                            mouseLeaveDelay={0}
                            mouseEnterDelay={0}
                            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                            arrow={true}

                          >
                            <span className="text_poup_tippy">Excluir.</span>
                          </Popup>

                        </div>

                        <input
                          id={`item-${value.id}`}
                          defaultChecked={value.status}
                          onChange={(event) => {

                          //  console.log("event: " + event.target.value);
                            window.studio_blockly.checkBoxStatus(event)
                          }}
                          className="status_file_input" type="checkbox"
                          value={value.id}
                          name="ckbx-style-14" />
                        <Popup
                          trigger={
                            <label htmlFor={`item-${value.id}`} onClick={() => {

                            }} className="btn_lock_fileEdit" style={{ color: value.status === 0 ? "#000" : "" }}>{value.status === 0 ? <BiLink /> : <BiUnlink />}</label>

                          }
                          position="bottom right"
                          on="hover"
                          closeOnDocumentClick={true}
                          onClose={() => {
                            //this.setState({ isOpen: false })
                          }}
                          mouseLeaveDelay={0}
                          mouseEnterDelay={0}
                          contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                          arrow={true}

                        >
                          <span className="text_poup_tippy">{value.status === 0 ? "Desvincular a" : "Vincular a "} cena.</span>
                        </Popup>



                      </div>
                    </div>


                    <button className="movedor btn_dragglable_item_fileEdit"><MdDragHandle /></button>
                  </div>

                </Pane>

              )


            })

          }

        </SortablePane>

      }

    </>);
  }

}

export default FileEdit;
