
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import $ from 'jquery';
import api from './Api';
import Swal from 'sweetalert2';

import Blockly from './studio/Blockly';
import Phaser from './studio/Phaser';

import ConfigurationModal from './modal/Configuration';
import AssocitionModal from './modal/AssocitionModal';
import AnimationModal from './modal/AnimationModal';
import ImportAssetScene from './modal/ImportAssetScene';
import AcerEditorViwer from './studio/AcerEditorViwer';
import UploadAssetModal from './modal/UploadAssetModal';
import ImportAsseAsset from './modal/ImportAsseAsset';
import Iframe from './studio/iframe.js'
import StoreModal from './modal/StoreModal.js'
//import Resizable from 're-resizable';

import './studio/css/studio.css';
import './studio/css/tabs.css';
import './studio/css/assets.css';
import './studio/css/log.css';
import './studio/css/customConfigAcer.css';


//New



//import Lottie from 'react-lottie';
//import animationData from './assets/animations/59228-block-loader.json'
import './studio/css/studio_new.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { push as Menu } from 'react-burger-menu'
//import { HiOutlineMenu } from 'react-icons/hi';
import { Twirl as Hamburger } from 'hamburger-react'
import { PanZoom } from 'react-easy-panzoom'


import Tooltip from 'rc-tooltip';
import { toast } from 'react-toastify';
//import { IoPlaySharp } from 'react-icons/io5';
import { AiOutlinePushpin, AiFillPushpin } from 'react-icons/ai';


require('dotenv').config();
/*

var styleHandle = {
  top: { display: 'none' },
  topLeft: { display: 'none' },
  left: {
    marginTop: -5,
    marginLeft: -3,
    top: "50%",
    left: 0,
    cursor: "ew-resize",
    border: "3px solid #999",
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    borderWidth: 5,
    borderColor: "#09f",
    width: 10,
    height: 10,
    boxSizing: "border-box",
    zIndex: 1
  },

  bottomLeft: {
    marginTop: -7,
    marginLeft: -2,
    top: "100%",
    left: 0,
    cursor: "nesw-resize",
    border: "3px solid #999",
    borderRight: "none",
    borderTop: "none",
    borderColor: "#09f",
    width: 10,
    height: 10,
    boxSizing: "border-box",
    zIndex: 1
  },
  bottom: {
    marginTop: -7,
    marginLeft: -5,
    top: "100%",
    left: "50%",
    cursor: "ns-resize",
    border: "3px solid #999",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    borderWidth: 5,
    borderColor: "#09f",
    width: 10,
    height: 10,
    boxSizing: "border-box",
    zIndex: 1
  },
  bottomRight: {
    marginTop: -7,
    marginLeft: -7,
    top: "100%",
    left: "100%",
    cursor: "nwse-resize",
    border: "3px solid #999",
    borderLeft: "none",
    borderTop: "none",
    borderColor: "#09f",
    width: 10,
    height: 10,
    boxSizing: "border-box",
    zIndex: 1
  },

  right: {
    marginTop: -5,
    marginLeft: -7,
    top: "50%",
    left: "100%",
    cursor: "ew-resize",
    border: "3px solid #999",
    borderTop: "none",
    borderLeft: "none",
    borderBottom: "none",
    borderWidth: 5,
    borderColor: "#09f",
    width: 10,
    height: 10,
    boxSizing: "border-box",
    zIndex: 1
  },
  topRight: { display: 'none' }
}

*/


class Studio extends Component {


  constructor(props) {

    super(props);

    this.state = {
      debug: 0,
      hiddenHeader: 0,
      hiddenFiles: 0,
      hiddenAssets: 0,
      hiddenConsole: 0,
      hiddenBarFiles: 0,
      description: null,
      defaultCanvasWidth: null,
      defaultCanvasHeigth: null,
      defaultFilesCreate: {},
      hiddenCanvasSize: 0,
      getOptions: 0,
      mostraResto: 0,
      hiddenResizeCanvas: 0,
      hiddenTrash: 0,
      hiddenCloseTab: 0,
      isOpenMenu: false,
      blockToolBox: false,
      iconPinToolBox: <AiOutlinePushpin />,
      disablePan: false,
      activeModalIframe: false,
      activePanZoom: undefined

    };

    this.resetWorksPace = this.resetWorksPace.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.externalLink = this.externalLink.bind(this);
    this.deleteGame = this.deleteGame.bind(this);
    this.verifyBlockToolBox = this.verifyBlockToolBox.bind(this);
    this.onUpdateModalIframe = this.onUpdateModalIframe.bind(this);
 
    window.studio = this;

  }

  //para mostar o canvas do phaser
  showCanvas() { $('.optionHeaderStudio').removeClass('ativo'); $('.optionHeaderStudio[type="showCanvas"]').addClass('ativo'); $('#').fadeIn(0); $("#divAcerEditor").fadeOut(0); }

  //Parar mostrar o javascript do blocklyfile
  showJs() {

    $('.optionHeaderStudio').removeClass('ativo'); $('.optionHeaderStudio[type="showJs"]').addClass('ativo'); $('#divPhaser').fadeOut(0); $("#divAcerEditor").fadeIn(0); window.studio_blockly.geraJS();

  }

  //para pegar as opções customizadas
  getOptions() { }


  //para resetar o tamanho da workspace
  resetWorksPace() {

    console.log('reset de workspace desabilitado');
    return false;

    var largura = $(window).width();
    var b = largura * 0.7; $("#resizeBlockly").css('width', b + 'px');
    window.studio_blockly.resizeBlockly();
    window.studio_blockly.saveSettings();
    toast.success('A largura da workspace foi redefinida.', { position: "bottom-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: false });

  }


  //para gerar os grupos
  returnImportFiles(file, type) {

    var assets = [];

    if (file === undefined) { console.error('Você deve informar o parametro file'); return false; }
    if (type === undefined) { type = null; }

    var f = false;


    if (parseInt(type) !== 0 && parseInt(type) !== 1 && parseInt(type) !== 2 && parseInt(type) !== 3 && type !== null) {

      console.error('O type deve ser 0,1,2 ou 3'); return false;

    }

    window.studio_blockly.state.filesList.forEach(function (item, index, array) {

      if (parseInt(item.id) === file) {

        f = true;

        item.importAssets.forEach(function (item2, index2, array2) {

          if (type === undefined || type === null) { assets.push(item2); } else {

            if (parseInt(type) === parseInt(item2.type)) {

              assets.push(item2);

            }

          }

        });

      }

    });

    if (!f) { console.error('O file informado não foi encontrado.'); return false; }

    return assets;

  }

  //para pegar todos os arquivos
  returnAllFiles() {

    return window.studio_blockly.state.filesList;

  }

  //para pegar todas as senas
  returnAllScenes() {

    var scenes = [];

    window.studio_blockly.state.filesList.forEach(function (item, index, array) {

      if (item.type === "scene") {

        scenes.push(item);

      }

    });

    return scenes;

  }


  setWh(){

    if(window.studio_blockly !== undefined){

      if(window.studio_blockly.state !== undefined){

        if(window.studio_blockly.state.game.phaserwidth !== undefined){

          var vrindex = window.parseQueryString();
          console.log('REDEFINIU AQUI 1 2 3 ');
          if(vrindex.width !== undefined && vrindex.height !== undefined){
            
            console.log('REDEFINIU AQUI 1 2 3 4 ');

            window.studio_blockly.state.game.phaserwidth = vrindex.width;
            window.studio_blockly.state.game.phaserheigth = vrindex.height;

            window.$("#divIframePhaser").css('width',vrindex.width); 
		        window.$("#divIframePhaser").css('height',vrindex.height);

          }

        }

      }

    }

  }

  componentDidUpdate(){

    this.setWh();

  }

  componentDidMount() {

    // if (localStorage.getItem("isOpenMenu") === "true") {
    //   // console.log("Setou LOCALSTORAGE : ", localStorage.getItem("isOpenMenu"))
    //   this.setState({ isOpenMenu: true })
    // }


    $("#loadingTxt").html('Carregando studio');

    //caso esteja informado opcao, busca os dados dela na api
    if (this.props.match.params.options === undefined) {

      //        console.log('deu undefined');
      this.setState({ mostraResto: true });

    } else {

      console.log('buscou');

      api.getStudioOptions(this.props.match.params.options).then((res) => {

        console.log('deu console log res data');

        console.log(res.data);

        res.data.mostraResto = true;
        this.setState(res.data);

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);

      });

    }
  }


  //para delar o game
  deleteGame() {

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Depois de excluir é impossível recuperar.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não, cancelar!'
    }).then((result) => {

      if (result.value) {

        //remove o arquivo na api
        api.deleteGame(this.props.match.params.game).then((res) => {

          toast.success('O projeto foi removido.', { position: "bottom-center", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: false });
          this.props.history.push('/');

        }).catch(error => {

          console.log(error.data);
          console.log(error);
          console.log(error.response);

        });

        console.log('removido');

      } else if (result.dismiss === Swal.DismissReason.cancel) {


      }

    });

  }


  externalLink() {

    window.open(sessionStorage.getItem('backend') + '/games/generatecode/' + this.props.match.params.game + '/', '_blank');

  }

  async debug(c) {


    var game = window.studio_blockly.state.game;
    game.debug = c;

    window.studio_blockly.setState({ game: game });


    await window.studio_blockly.saveSettings();
    this.setState({ a: 1 });

  }

  openAssetStore() {

    window.$("#assetsStoreModal").modal();
    this.setState({ a: '1' });

  }


  async verifyBlockToolBox() {
    var value = await this.state.blockToolBox;


    if (value) {
      // console.log("passou como true")
      $(".blocklyFlyout").width("300px");
      $(".btn_pin_fixed_Phaser").show();
    } else {
      // console.log("passou como false")
      $(".blocklyFlyout").width("0px");
      $(".btn_pin_fixed_Phaser").hide();
    }
  }


  async isMenuOpen(value) {
    //console.log("Value: ", value)
    var isValue = await value.isOpen;

    if (isValue === true) {
      $("#page-wrap").css('margin-left', '450px');

      $("#divIframePhaser").css('left', '0px');
      $("#divIframePhaser").css('top', '0px');
      $("#divIframePhaser").css('max-width', 'calc(95vw - 375px)');



    } else {
      $("#page-wrap").css('margin-left', '0px');

      $("#divIframePhaser").css('left', '');
      $("#divIframePhaser").css('top', '');
      $("#divIframePhaser").css('max-width', '');
    }
  }


  async onUpdateModalIframe(value) {
    await this.setState({ activeModalIframe: value })
  }

  render() {




    // var url = sessionStorage.getItem('backend') + '/games/generatecode/' + window.studio.props.match.params.game + '/';
    //value2[0].className

    var Hei = window.innerHeight;
    Hei = Hei - 60;



    //$(".container_pageStudio_blocks_menu").css('z-index', '1');
    //$(".container_pageStudio_blocks_menu").css('z-index', '-1');

    // $('.blocklyFlyout').css('transform', 'translate(306px, 0px) !important');

    $(document).ready(function () {
      $(".blocklyFlyout").width("0px");
      $(".btn_pin_fixed_Phaser").hide();
      $(".window_loading_iframe").hide();
    });

    $(".blocklyTreeRoot").focus(() => {

      if (localStorage.getItem("layout") !== "vertical") {
        $(".blocklyFlyout").width("300px");
        $(".btn_pin_fixed_Phaser").show();
      }

    });

    //  $(".content_infos_dimensions").hide();
    $(".content_infos_dimensions").css('opacity', "0");
    $(".blocklyTreeRoot").blur(() => {

      setTimeout(() => {
        this.verifyBlockToolBox();
      }, 100);

    });

    $("#divIframePhaser span div").hover(() => {
      $(".content_infos_dimensions").css('opacity', "1");
      //$(".content_infos_dimensions").show();
    })






    $("#divIframePhaser").mouseover(() => {

      if (this.state.activePanZoom === false) {
        $("#pageStudio div:nth-child(2)").addClass("indexIframe");
      }

      //$(".content_infos_dimensions").show();
    })


    $("#divIframePhaser").mouseout(() => {
      if (this.state.activePanZoom === false) {
        $("#pageStudio div:nth-child(2)").removeClass("indexIframe");
      }

    });





    /* 
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }; */


    if (localStorage.getItem("layout") === "vertical") {
      // console.log("layout: vertical");

      $(".blocklyToolboxDiv").addClass("blocklyToolboxDiv_vertical");
      $(".blocklyToolboxDiv").removeClass("blocklyToolboxDiv_horizontal");

      $(".container_pageStudio_blocks_menu").addClass("container_pageStudio_blocks_menu_vertical");
      $(".container_pageStudio_blocks_menu").removeClass("container_pageStudio_blocks_menu_horizontal");

      $(".blocklySvg").addClass("blocklySvg_vertical");
      $(".blocklySvg").removeClass("blocklySvg_horizontal");

      $(".btn_pin_fixed_Phaser").addClass("btn_pin_fixed_Phaser_vertical");
      $(".btn_pin_fixed_Phaser").removeClass("btn_pin_fixed_Phaser_horizontal");


      $(".blocklyFlyout").addClass("blocklyFlyout_vertical");
      $(".blocklyFlyout").removeClass("blocklyFlyout_horizontal");

      $("#selectToolBox").addClass("selectToolBox_vertical");
      $("#selectToolBox").removeClass("selectToolBox_horizontal");

      $(".rdTabWrapper ul").addClass("rdTabWrapper_vertical");
      $(".rdTabWrapper ul").removeClass("rdTabWrapper_horizontal");

      $("#content_iframe_Phaser").addClass("content_iframe_Phaser_vertical");
      $("#content_iframe_Phaser").removeClass("content_iframe_Phaser_horizontal");

      $(".fileListEs").addClass("fileListEs_vertical");
      $(".fileListEs").removeClass("fileListEs_horizontal");

      $(".injectionDiv").addClass("injectionDiv_vertical");
      $(".injectionDiv").removeClass("injectionDiv_horizontal");

      $(".myWrapper").addClass("myWrapper_vertical");
      $(".myWrapper").removeClass("myWrapper_horizontal");

      $("#selectToolBox").addClass("selectToolBox_vertical");
      $("#selectToolBox").removeClass("selectToolBox_horizontal");


      $(".content_infos_dimensions").addClass("content_infos_dimensions_vertical");
      $(".content_infos_dimensions").removeClass("content_infos_dimensions_horizontal");

      $(".react-draggable-dragged").addClass("react-draggable_vertical");
      $(".react-draggable-dragged").removeClass("react-draggable_horizontal");


      $(".myWrapper2").addClass("myWrapper_vertical2");
      $(".myWrapper2").removeClass("myWrapper_horizontal2");

      $(".btn_pin_fixed_Phaser").addClass("btn_pin_fixed_Phaser_vertical");
      $(".btn_pin_fixed_Phaser").removeClass("btn_pin_fixed_Phaser_horizontal");

      $(".blocklyTreeItem").addClass("blocklyTreeItem_vertical");
      $(".blocklyTreeItem").removeClass("blocklyTreeItem_horizontal");


      window.translateBlockly = "translate(99px, 10px)"

    }

    if ((localStorage.getItem("layout") === "horizontal") || (localStorage.getItem("layout") === undefined) || (localStorage.getItem("layout") === null)) {
      //  console.log("layout: horizontal");

      $(".blocklyToolboxDiv").addClass("blocklyToolboxDiv_horizontal");
      $(".blocklyToolboxDiv").removeClass("blocklyToolboxDiv_vertical");

      $(".container_pageStudio_blocks_menu").addClass("container_pageStudio_blocks_menu_horizontal");
      $(".container_pageStudio_blocks_menu").removeClass("container_pageStudio_blocks_menu_vertical");

      $(".blocklySvg").addClass("blocklySvg_horizontal");
      $(".blocklySvg").removeClass("blocklySvg_horizontal");

      $(".btn_pin_fixed_Phaser").addClass("btn_pin_fixed_Phaser_horizontal");
      $(".btn_pin_fixed_Phaser").removeClass("btn_pin_fixed_Phaser_vertical");


      $(".blocklyFlyout").addClass("blocklyFlyout_horizontal");
      $(".blocklyFlyout").removeClass("blocklyFlyout_vertical");

      $("#selectToolBox").addClass("selectToolBox_horizontal");
      $("#selectToolBox").removeClass("selectToolBox_vertical");

      $(".rdTabWrapper ul").addClass("rdTabWrapper_horizontal");
      $(".rdTabWrapper ul").removeClass("rdTabWrapper_vertical");

      $("#content_iframe_Phaser").addClass("content_iframe_Phaser_horizontal");
      $("#content_iframe_Phaser").removeClass("content_iframe_Phaser_vertical");


      $(".fileListEs").addClass("fileListEs_horizontal");
      $(".fileListEs").removeClass("fileListEs_vertical");

      $(".injectionDiv").addClass("injectionDiv_horizontal");
      $(".injectionDiv").removeClass("injectionDiv_vertical");

      $(".myWrapper").addClass("myWrapper_horizontal");
      $(".myWrapper").removeClass("myWrapper_vertical");

      $("#selectToolBox").addClass("selectToolBox_horizontal");
      $("#selectToolBox").removeClass("selectToolBox_vertical");


      $(".content_infos_dimensions").addClass("content_infos_dimensions_horizontal");
      $(".content_infos_dimensions").removeClass("content_infos_dimensions_vertical");


      $(".react-draggable-dragged").addClass("react-draggable_horizontal");
      $(".react-draggable-dragged").removeClass("react-draggable_vertical");


      $(".myWrapper2").addClass("myWrapper_horizontal2");
      $(".myWrapper2").removeClass("myWrapper_vertical2");


      $(".btn_pin_fixed_Phaser").addClass("btn_pin_fixed_Phaser_horizontal");
      $(".btn_pin_fixed_Phaser").removeClass("btn_pin_fixed_Phaser_vertical");



      $(".blocklyTreeItem").addClass("blocklyTreeItem_horizontal");
      $(".blocklyTreeItem").removeClass("blocklyTreeItem_vertical");


      window.translateBlockly = "translate(99px, 0px)"
    }

    return (
      <>

        <main id="page-wrap">
          <>

            <style>{' body{ overflow: hidden; background-color:#FFF; }  .iframeAsset{ height: ' + Hei + 'px; } '}</style>

            <link rel='stylesheet' href='/css/studio.css' type='text/css' media='all' />

            {(this.state.hiddenHeader === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenHeader.css' type='text/css' media='all' />
            }

            {(this.state.hiddenFiles === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenFiles.css' type='text/css' media='all' />
            }

            {(this.state.hiddenAssets === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenAssets.css' type='text/css' media='all' />
            }

            {(this.state.hiddenConsole === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenConsole.css' type='text/css' media='all' />
            }

            {(this.state.hiddenBarFiles === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenBarFiles.css' type='text/css' media='all' />
            }

            {(this.state.hiddenCanvasSize === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenCanvasSize.css' type='text/css' media='all' />
            }

            {(this.state.hiddenResizeCanvas === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/resizeCanvas.css' type='text/css' media='all' />
            }

            {
              (this.state.hiddenTrash === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenTrash.css' type='text/css' media='all' />
            }

            {
              (this.state.hiddenCloseTab === 1) &&
              <link rel='stylesheet' href='/css/optionStudio/hiddenCloseTab.css' type='text/css' media='all' />
            }

          </>

          <div id="pageStudio" >

            <div hidden className="studio_options">

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Voltar a página inicial</span>}>
                <div className="option option_1 hvr-hang">   <Link to="/"><img alt="" src="/media/home_user_places.png" /></Link> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Assets</span>}>
                <div hidden className="option option_1 hvr-hang" data-target="#assetsModal" data-toggle="modal"> <img alt="" src="/media/1528790.svg" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Arquivos</span>}>
                <div hidden className="option option_2 hvr-hang" data-toggle="modal" data-target="#filesModal"> <img alt="" src="/media/148957.svg" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Configurações do projeto</span>}>
                <div className="option option_3 hvr-hang" onClick={() => window.gameConfigurationModal.resetConfiguration()} > <img alt="" src="/media/builder_png_px_website.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Resetar largura da workspace</span>}>
                <div onClick={() => this.resetWorksPace()} className="option option_3 hvr-hang"> <img alt="" src="/media/synchronize_sync_refresh.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Abrir em nova aba</span>}>
                <div onClick={() => this.externalLink()} className="option option_3 hvr-hang"> <img style={{ height: '36px' }} alt="" src="/media/tab_new_add_plus.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Recompilar</span>}>
                <div onClick={() => window.studio_blockly.regenerateAllCodes(true)} className="option option_3 hvr-hang"> <img style={{ height: '36px' }} alt="" src="/media/package_code.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Asset Store</span>}>
                <div onClick={() => this.openAssetStore()} className="option option_3 hvr-hang"> <img style={{ height: '36px' }} alt="" src="/media/rRp1Ezk.png" /> </div>
              </Tooltip>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Reinciar game</span>}>
                <div onClick={() => window.phaser.startGame()} className="option option_3 hvr-hang"> <img style={{ height: '36px' }} alt="" src="/media/arrow_submit_play_next_right.png" /> </div>
              </Tooltip>

              {

                (this.state.debug === true) &&
                <font className="eae1">

                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 1) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug on</span>}>
                      <div onClick={() => this.debug(false)} className="option option_3 hvr-hang "> <img style={{ height: '36px' }} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>

                  }

                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 0) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug off</span>}>
                      <div onClick={() => this.debug(1)} className="option option_3 hvr-hang debugon"> <img style={{ height: '36px', filter: 'grayscale(100%)' }} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>

                  }

                </font>


              }


              <div className="modal fade" id="assetsStoreModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: 'initial' }}>
                  <div className="modal-content">

                    <style>{'.fecharAsset{ cursor:pointer; position: absolute; left: calc(100% - 55px); z-index: 9999; font-size: 29px; top: 62px; color: #fff; }'}</style>
                    <button className="fecharAsset" onClick={() => window.$('#assetsStoreModal').modal('hide')}>
                      <i className="fas fa-times"></i>
                    </button>
                    <iframe title="store" className="iframeAsset" src={window.urlstore} frameBorder="0"></iframe>

                  </div>
                </div>
              </div>


              <font id="buttonAssociaFile" style={{ display: 'none' }}>
                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Associção de arquivo</span>}>
                  <div onClick={() => window.AssocitionModal.openAssocitionModal()} className="option option_3 hvr-hang"> <img style={{ height: '36px' }} alt="" src="/media/seo-web-code-icon.png" /> </div>
                </Tooltip>
              </font>

              <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Deletar projeto</span>}>
                <div onClick={() => this.deleteGame()} style={{ display: 'none' }} className="option option_3 hvr-hang"> <img style={{ height: '36px' }} alt="" src="/media/trashcan_empty_alt.png" /> </div>
              </Tooltip>

            </div>


            <PanZoom
              autoCenter={localStorage.getItem("layout") === "vertical"}
              autoCenterZoomLevel={0.1}
              disableKeyInteraction={true}
              realPinch={true}
              boundaryRatioVertical={0}
              boundaryRatioHorizontal={0}
              enableBoundingBox={true}
              onPanStart={() => {

                if (this.state.activePanZoom === false || this.state.activePanZoom === undefined) {
                  this.setState({ activePanZoom: true })

                  $("#pageStudio div:nth-child(2)").addClass("indexIframe");
                }


              }}
              onPanEnd={() => {

                if (this.state.activePanZoom === true || this.state.activePanZoom === undefined) {
                  this.setState({ activePanZoom: false });
                  $("#pageStudio div:nth-child(2)").removeClass("indexIframe");
                }


              }}
              style={{ width: '100%', height: '100%' }}
            >
              <Iframe {...this.props} nameScene={this.state.nameScene} activePanZoom={this.state.activePanZoom} activeModalIframe={this.state.activeModalIframe} />
            </PanZoom>



            <div className="container_pageStudio_blocks_menu">

              <Popup
                trigger={
                  <button
                    onClick={() => {
                      this.setState({ blockToolBox: !this.state.blockToolBox });

                    }}
                    className={"btn_pin_fixed_Phaser " + (this.state.blockToolBox ? " active " : "")}>
                    {this.state.blockToolBox ? <AiFillPushpin /> : <AiOutlinePushpin />}
                  </button>
                }
                position="bottom right"
                on="hover"
                closeOnDocumentClick={true}
                onClose={() => {
                  //this.setState({ isOpen: false })
                }}
                mouseLeaveDelay={0}
                mouseEnterDelay={0}
                contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                arrow={true}

              >
                <span className="text_poup_tippy">{this.state.blockToolBox ? "Desafixar a caixa de ferramentas " : "Fixar a caixa de ferramentas."} </span>
              </Popup>


              <div className="studio_2" style={{ display: 'flex' }}>

                <div id="divBlockly">

                  {
                    (this.state.mostraResto) &&
                    <Blockly  {...this.props} onUpdateModalIframe={(e) => this.onUpdateModalIframe(e)} />
                  }

                  <Popup
                    trigger={
                      <button className="btn_play_studio" onClick={() => {
                        window.phaser.startGame();
                        $("#IframePhaser").ready(function () {
                          //  console.log("document loaded");
                          $(".window_loading_iframe").show();
                        });

                        $("#IframePhaser").on("load", function () {
                          //   console.log("window loaded");
                          $(".window_loading_iframe").hide();

                        });

                      }}></button>
                    }
                    position="left center"
                    on="hover"
                    closeOnDocumentClick={true}
                    onClose={() => {
                      //this.setState({ isOpen: false })
                    }}
                    mouseLeaveDelay={0}
                    mouseEnterDelay={0}
                    contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                    arrow={true}

                  >
                    <span className="text_poup_tippy">Executar o jogo.</span>
                  </Popup>



                </div>

              </div>

              <div hidden className="content_menu_right_studio">

                {
                  /*
                  <div className="headerLeftStudio">
                  <div className="optionHeaderStudio ativo" type="showCanvas" onClick={() => this.showCanvas()}><font>CANVAS</font></div>
                  <div className="optionHeaderStudio" type="showJs" onClick={() => this.showJs()}><font>JAVASCRIPT</font>
    
                    <font className="fontsize" style={{ float: 'right', marginRight: '0px', fontWeight: '200' }}>
    
                      <font id="tamanhoFontAcer">16px</font> <button className="fas fa-plus btfontsize" onClick={() => window.acereditor.setFontSize(1)}></button> <button className="fas fa-minus btfontsize" onClick={() => window.acereditor.setFontSize(-1)}></button>
    
    
                    </font>
    
                  </div>
                </div>
                */
                }

                <div>
                  <div id="divPhaser">
                    <button className="btn_pin_fixed_Phaser">Fixed</button>
                    {
                      (this.state.mostraResto) &&
                      <Phaser {...this.props} onUpdateModalIframe={(e) => this.onUpdateModalIframe(e)} />
                    }

                  </div>
                  <div id="divAcerEditor" style={{ display: 'none' }}>

                    {
                      /*
                      (this.state.mostraResto) &&
                      <AcerEditorViwer />
                      */
                    }

                  </div>
                </div>

              </div>

            </div>


          </div>

          {(this.state.mostraResto) &&
            <>
              <ConfigurationModal />
              <AssocitionModal />
              <AnimationModal />
              <ImportAssetScene />
              <UploadAssetModal />
              <ImportAsseAsset />
            </>
          }






          <StoreModal />


          <div className="btn_show_menu" >
            <Hamburger

              size={20}
              toggled={this.state.isOpenMenu}
              toggle={(e) => {
                this.setState({ isOpenMenu: !this.state.isOpenMenu })
                // localStorage.setItem("isOpenMenu", !this.state.isOpenMenu);
              }}


            />
          </div>



        </main>


        <Menu
          onClose={() => {
            this.setState({ isOpenMenu: false });

          }}
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          right
          width={450}
          isOpen={this.state.isOpenMenu}
          onStateChange={(e) => this.isMenuOpen(e)}
        >



          <div className="content_menu_right_studio">

            <>
              <div id="divPhaser">

                {
                  (this.state.mostraResto) &&
                  <Phaser {...this.props} />
                }

              </div>
              <div id="divAcerEditor" style={{ display: 'none' }}>

                {(this.state.mostraResto) &&
                  <AcerEditorViwer {...this.props} />
                }

              </div>
            </>

          </div>

        </Menu>
      </>

    )


  }


}

export default Studio;