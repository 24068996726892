import React, { Component } from 'react';
//import{ Link } from 'react-router-dom';

import $ from 'jquery';
import api from './../Api';
//import Resizable from 're-resizable';


//import brace from 'brace';
import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/solarized_dark';
import 'brace/theme/terminal';

import 'brace/snippets/html';
import 'brace/ext/language_tools';



require('dotenv').config();
 
class AcerEditorViwer extends Component {
  
  constructor(props){

    super(props);

    this.state = {largura:'100px'}
    this.fontsize = 16;
    this.setValue = this.setValue.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setFontSize = this.setFontSize.bind(this);
    window.acereditor = this;

  }

  //para setar o valorn no ace editor
  setValue(valor){
      
    this.refs.aceEditor.editor.setFontSize(this.fontsize+'px');
  	this.refs.aceEditor.editor.setValue(valor,-1);

  }

  //para alterar a font no ace editor 
  setFontSize(size){
 
    var atual = this.fontsize + size;

    console.log(atual);

    if(atual === 15 || atual === 41){ return false; }

    this.fontsize = atual;
    this.refs.aceEditor.editor.setFontSize(atual+'px');
    $("#tamanhoFontAcer").html(atual+'px');

    api.saveSettings(window.studio.props.match.params.game,{FontSize:atual}).then((res) => {

        window.studio_blockly.setState({game:res.data});

    }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
  
    });

  }
 
  render() {
    
    return (<> <AceEditor
    mode="javascript"
    ref="aceEditor"
    theme="monokai" 
    name="aceEditor"
    editorProps={{$blockScrolling: true}}
    width="auto"
    height={this.state.largura}
    showPrintMargin={false}
    readOnly={true}

  /> </>)


  }


}

export default AcerEditorViwer;