import React, { Component } from 'react';
//import $ from 'jquery';
import{ BrowserRouter as Router, Route } from 'react-router-dom';
//import { browserHistory } from 'react-router';

import ListaGames from './ListaGames'; 
import Studio from './Studio'; 
import Header from './Header';
import Help from './Help';
import Configuracao from './Configuracao';

import Usuarios from './pages/Usuarios';

import Blockly_blocks from './pages/Blockly_blocks';
import Blockly_categoria from './pages/Blockly_categoria';
import Blockly_secao from './pages/Blockly_secao';

class Principal extends Component {
  

  constructor(props){

    super(props); 

    window.principal = this;
    
  }


  componentDidMount(){}
  
  render() {
  
    return (<>
      
      <Router>
          
      	<Header/>

        <div className="principal_2">


          { (window.app.state.dataUser !== undefined) && 
          <>
            {

              (parseInt(window.app.state.dataUser.admin) === 1) && 
              <>

                <Route exact path="/configuracao" component={Configuracao} />
                <Route exact path="/usuarios" component={Usuarios} />
                <Route exact path="/bl_categoria" component={Blockly_categoria} />
                <Route exact path="/bl_secao" component={Blockly_secao} />
                <Route exact path="/bl_blocks" component={Blockly_blocks} />

              </>

            }
             
          </>
          }

        </div>

      </Router>


    </>);

  }


}

export default Principal;
