import React, { Component } from 'react';
import $ from 'jquery';

//import './assets/login-v3.default.css';
//import './assets/vendors.bundle.css';
//import api from './../Api';
//import Sortable from 'sortablejs';

//import Swal from 'sweetalert2';

//import Login from './Login';
//import Head from './Head';
//import { toast } from 'react-toastify';
 
class AssocitionModal extends Component {

	constructor(props){

    	super(props);
    	this.checkAssociation = this.checkAssociation.bind(this);
		window.AssocitionModal = this;
	}

	componentDidMount(){}

	//para abrir o modal
	openAssocitionModal(id){

		this.setState({a:1});

		window.$("#AssocitionModal").modal();
		window.$("#associationFile").val('');
		window.$("#associationAsset").val('');
  		 
  		if(id !== undefined){
 
  			setTimeout(function(){ window.$('#associationAsset').val(id); }, 10); 

  		}

  		setTimeout(function(){ this.checkAssociation(); }.bind(this), 11); 
	}

 	//para atualizar a associação do arquivo
 	updateAssociation(){

 		var valor = window.$("#associationFile").val();
 		if(valor === ""){ valor = null; }
 		window.studio_blockly.updateAsset(window.$("#associationAsset").val(),'gamefiles',window.$("#associationFile").val());

 	}

 	//para saber com qual arquivo ele esta associado
 	checkAssociation(){

 		var asset = window.$("#associationAsset").val();
 		console.log(asset);

 		if(asset !== ""){

 			var assets = window.studio_blockly.state.Assets;
 			assets.forEach(function(item,index,array){

 				if(parseInt(asset) === parseInt(item.id)){
 
 					if(item.gamefiles === null){

 						window.$("#associationFile").val("");

 					}else{
 
 						window.$("#associationFile").val(item.gamefiles.id);

 					}

 				}

 			});
    		  
 			$("#associationFile").prop('disabled', false);

 		}else{

 			$("#associationFile").val('');
 			$("#associationFile").prop('disabled', true);

 		}
 		

 	}


	render(){

		return(<>

			<div className="modal fade" id="AssocitionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
			  <div className="modal-dialog" role="document">
			    <div className="modal-content">
					
					<br/>
					<center><h3>Associação de arquivo</h3></center>
					
					<center>Essa opção permite que você associe o <strong>file</strong> ao <strong>asset</strong>, apenas para fins de organização. 
					Ao associar, você podera abrir o <strong>file</strong> a partir do <strong>asset</strong>.</center>

					{ (window.studio_blockly.state.game !== undefined) &&
					<form >
			    	
				    	<div className="modal-body">

				    		<div className="row">

				    			<div className="col-md-6">
				    				<div className="form-group">
										<center><label htmlFor="exampleInputEmail1">Asset:</label></center>
										<select onChange={ () => this.checkAssociation() } name="name" className="form-control" id="associationAsset">

											<option value=""></option>

											{

												(window.studio_blockly.state.Assets.length >= 0) &&
												window.studio_blockly.state.Assets.map(function(item, i){
                                
					                                return <option key={item.id} value={item.id} >{item.name}</option>

				                              	})

											}
										</select>
									</div>
				    			</div>

				    			<div className="col-md-6">
				    				<div className="form-group">
										<center><label htmlFor="exampleInputEmail1">File:</label></center>
										<select onChange={ () => this.updateAssociation() } name="name" className="form-control" id="associationFile">

											<option></option>
											{

												(window.studio_blockly.state.filesList.length >= 0) &&
												window.studio_blockly.state.filesList.map(function(item, i){
                                
					                                return <option key={item.id} value={item.id} >{item.name}</option>

				                              	})

											}

										</select> 
									</div>
				    			</div>

				    		</div>
							 
 

				      	</div>

				      	<div className="modal-footer">
				      		<button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button> 
				      	</div>

			      	</form>
			      	}
			     
			    </div>
			  </div>
			</div>
			</>)

	}


}

export default AssocitionModal;