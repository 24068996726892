import React, { useState } from 'react'

import Resizable from 're-resizable';
import $ from 'jquery';
import animationData from '../assets/animations/59228-block-loader.json'
import Lottie from 'react-lottie';

var styleHandle = {
    top: { display: 'none' },
    topLeft: { display: 'none' },
    left: {
        marginTop: -5,
        marginLeft: -3,
        top: "50%",
        left: 0,
        cursor: "ew-resize",
        border: "3px solid #999",
        borderTop: "none",
        borderRight: "none",
        borderBottom: "none",
        borderWidth: 5,
        borderColor: "#09f",
        width: 10,
        height: 10,
        boxSizing: "border-box",
        zIndex: 1
    },

    bottomLeft: {
        marginTop: -7,
        marginLeft: -2,
        top: "100%",
        left: 0,
        cursor: "nesw-resize",
        border: "3px solid #999",
        borderRight: "none",
        borderTop: "none",
        borderColor: "#09f",
        width: 10,
        height: 10,
        boxSizing: "border-box",
        zIndex: 1
    },
    bottom: {
        marginTop: -7,
        marginLeft: -5,
        top: "100%",
        left: "50%",
        cursor: "ns-resize",
        border: "3px solid #999",
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
        borderWidth: 5,
        borderColor: "#09f",
        width: 10,
        height: 10,
        boxSizing: "border-box",
        zIndex: 1
    },
    bottomRight: {
        marginTop: -7,
        marginLeft: -7,
        top: "100%",
        left: "100%",
        cursor: "nwse-resize",
        border: "3px solid #999",
        borderLeft: "none",
        borderTop: "none",
        borderColor: "#09f",
        width: 10,
        height: 10,
        boxSizing: "border-box",
        zIndex: 1
    },

    right: {
        marginTop: -5,
        marginLeft: -7,
        top: "50%",
        left: "100%",
        cursor: "ew-resize",
        border: "3px solid #999",
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderWidth: 5,
        borderColor: "#09f",
        width: 10,
        height: 10,
        boxSizing: "border-box",
        zIndex: 1
    },
    topRight: { display: 'none' }
}



const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};




export default function Iframe(props) {
    var url = sessionStorage.getItem('backend') + '/games/generatecode/' + window.studio.props.match.params.game + '/';

    const [loadIframe, setLoadIframe] = useState(false);

    // function alteraLargura(valor, total) {

    //     var largura = $('#divIframePhaser').width();
    //     largura = largura + valor;
    //     $('#divIframePhaser').css('width', largura + 'px');

    //     if (total) {

    //         largura = valor;

    //     }

    //     $("#wphaser").html('100');
    //     window.studio_blockly.saveSettings();

    // }

    // function alteraAltura(valor, total) {

    //     var altura = $('#divIframePhaser').height();
    //     altura = altura + valor;
    //     $('#divIframePhaser').css('height', altura + 'px');

    //     if (total) {

    //         altura = valor;

    //     }

    //     $("#hphaser").html(altura);
    //     window.studio_blockly.saveSettings();

    // }

    // function onMouseMoveIframe(event) {
    //     $("#popover").show().css({ left: event.pageX - 568, top: event.pageY - 30 });
    //     console.log("Passou o mouse :" + "LEFT: " + window.studio_blockly.state.game.phaserwidth + "TOP: " + window.studio_blockly.state.game.phaserheigth)
    // }

    // function onMouseOutIframe(event) {
    //     console.log("Tirou o mouse");

    // }



    return (

        <div
            id="content_iframe_Phaser"

            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
            {
                /*
                
                        <div className="content_window_IframePhaser_empty">
            
                            <div>
                                <span className="span_name_canvasSize">Largura: </span>
                                <button
                                    className="alterCanvasSize remove_canvasSeize "
                                    onClick={() => alteraLargura(-1)}
            
                                >
                                    <i className="fas fa-minus" style={{ color: '#000' }}></i>
                                </button>
            
            
                                <span id="wphaser" className="value_canvasSize">{window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserwidth : "Carregando..."}</span>
            
                                <button
                                    onClick={() => alteraLargura(1)}
            
                                    className="alterCanvasSize add_canvasSeize">
                                    <i className="fas fa-plus" style={{ color: '#000' }}></i>
                                </button>
            
            
                                <span className="span_name_canvasSize">Altura: </span>
            
                                <button
                                    onClick={() => alteraAltura(-1)}
                                    
                                    className="alterCanvasSize remove_canvasSeize">
                                    <i className="fas fa-minus" style={{ color: '#000' }}></i>
                                </button>
                                <span id="hphaser" className="value_canvasSize">{window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserheigth : "Carregando..."}</span>
                                <button
                                    className="alterCanvasSize add_canvasSeize"
                                    onClick={() => alteraAltura(1)}
                                    >
                                    <i style={{ marginLeft: '4px', color: '#000' }} className="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
            
            */
            }
            <Resizable
                enable={{ top: false, right: false, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                id="divIframePhaser"
                onResize={(e, direction, ref, d) => {
                    // window.phaser.calculaAltura();
                    $("#wphaser").html($("#divIframePhaser").width());
                    $("#hphaser").html($("#divIframePhaser").height());
                }}
                onResizeStop={() => window.studio_blockly.saveSettings()}
                style={{}}
                handleStyles={styleHandle}
                bounds="window"
            >

                <div
                    onMouseOut={() => {
                        //console.log("saiu");
                        $('#popover').css('display', 'none');
                        // $(".content_infos_dimensions").hide();
                        $(".content_infos_dimensions").css('opacity', "0");
                    }}
                    onMouseOver={() => {
                        //console.log("iframe: ", window.$("#divGameRender").length)
                        if (loadIframe) {
                            $('#popover').css('display', 'flex');

                        }

                        // $(".content_infos_dimensions").show();
                        $(".content_infos_dimensions").css('opacity', "1");
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}


                >

                    {

                        props.activeModalIframe === false && (
                            <>


                                <iframe

                                    onLoad={() => {
                                        setLoadIframe(true)
                                    }}

                                    id="IframePhaser"
                                    title="phaserframe"
                                    src_old={url}
                                    frameBorder="0"
                                    style={{
                                        boxShadow: '0 7px 30px -10px rgb(150 170 180 / 50%)',
                                        width: '100%',
                                        height: '100%',
                                        background: '#fff'
                                    }} >


                                </iframe>

                                {
                                    props.activePanZoom &&
                                    <div className="activePanZoom">

                                    </div>
                                }

                                <div className="window_loading_iframe">
                                    <Lottie
                                        options={defaultOptions}
                                        height={200}
                                        width={200}
                                        isClickToPauseDisabled={true}
                                        autoplay={true}
                                    />
                                </div>




                                {
                                    /*
                                                     <div id="popover" >
                 
                                                     <span id="eixo_x" className="content_data_iframePhaser"></span>
                                                     <span id="eixo_y" className="content_data_iframePhaser"></span>
                 
                                                 </div>
                                                 */
                                }




                            </>
                        )

                    }



                </div>

            </Resizable>

            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>


                <div className="content_infos_dimensions">


                    <span id="container_dimensions_canvasSize">

                        <span className="span_name_canvasSize">Largura: </span>
                        <span id="wphaser" className="value_canvasSize">{window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserwidth : "Carregando..."}</span>
                        <span className="span_name_canvasSize">Altura: </span>
                        <span id="hphaser" className="value_canvasSize">{window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserheigth : "Carregando..."}</span>
                    </span>

                    <span id="container_eixos_out" >

                        <span id="eixo_x" className="content_data_iframePhaser"></span>
                        <span id="eixo_y" className="content_data_iframePhaser"></span>

                    </span>




                </div>
            </div>


        </div >
    )
}