import React, { Component } from 'react';
import $ from 'jquery';

import JSONInput from 'react-json-editor-ajrm';
import api from './../Api';

import { toast } from 'react-toastify';

class JsonEditor extends Component {
  
	constructor(props){

		super(props);
 		this.state = {};
		window.jsonEditor = this;

	}

	componentWillUnmount(){

		delete window.jsonEditor;

	}

	changeJson(event){ 

		console.log(event);

		//Caso seja diferente de undefined significa que o é um json válido.
		if(event.jsObject === undefined){ return false; }

		var dados = {javascriptAceEditor:JSON.stringify(event.jsObject)};

		//Para salvar o arquivo no banco de dados.
		api.updateFile($("#fileselected").val(),dados).then((res) => {

			console.log('salvou json');
			
			if($('.Toastify__toast--success').length !== 0){ return false; }
			toast.success('O arquivo json foi atualizado.', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: false}); 
        	
 

			var files = window.studio_blockly.state.filesList;

			//Assim que atualiza.... pego a posição do arquivo dele no array e substituo
			var elementPos = files.map(function(x) {return x.id; }).indexOf(parseInt($("#fileselected").val())); 
        	files[elementPos] = res.data;

          	window.studio_blockly.setState({filesList:files});

	    }).catch(error => {

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response);
	  
	    });


	}

 	render(){

 		var jsonValue;
 		try {

 			//statements
 			var file = window.studio_blockly.getFileState(window.studio_blockly.state.game.fileSelected)
 			jsonValue = JSON.parse(file.javascriptAceEditor);

 		} catch(e) {

 			// statements
 			jsonValue = {};

 		}


 		return(<React.Fragment>
 			
 			<link rel='stylesheet' id='mqueries-style-css' href='/css/customJsonEditor.css' type='text/css' media='all' />
			<JSONInput onChange={this.changeJson} colors={{background:"#424b51",background_warning:"#4f585e"}} id='jsonEditor' height={this.state.height+'px'} width placeholder={jsonValue}/>

 		</React.Fragment>);

 	}

}
		
export default JsonEditor;
