import React, { Component } from 'react';
//import{ Link } from 'react-router-dom';

import $ from 'jquery';
//import api from './Api';
import Resizable from 're-resizable';

import TableAssets from './../table/Assets';
import FileEdit from './../table/FileEdit';
import Tooltip from 'rc-tooltip';

import { IconContext } from "react-icons";
import { IoMdAdd, IoIosConstruct, IoMdAppstore } from 'react-icons/io'
import { IoBugSharp, IoReload, IoHome } from 'react-icons/io5'
import { VscEmptyWindow } from 'react-icons/vsc'
import { Twirl as Hamburger } from 'hamburger-react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { DiJavascript1, DiTerminalBadge } from 'react-icons/di'
import { FiSettings } from 'react-icons/fi'
import { FcFolder, FcGallery } from 'react-icons/fc'
//import { VscClose } from 'react-icons/vsc'
import { CgClose } from 'react-icons/cg';
import AceEditorMaster from '../studio/AceEditorMaster.js';
//import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/github';
import Modal from 'react-modal';

require('dotenv').config();


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};



class Phaser extends Component {


  constructor(props) {

    super(props);

    this.alteraLargura = this.alteraLargura.bind(this);
    this.alteraAltura = this.alteraAltura.bind(this);

    this.state = {
      error: [],
      isOpen: false,
      isOpenLayout: false,
      isActiveOpacity: true,
      isOpenModalShowJS: false,
      isOpenTerminal: false,
      isOpenDimensionsIframe: false
    };

    window.phaser = this;

    this.showJs = this.showJs.bind(this);
    this.externalLink = this.externalLink.bind(this);
  }

  componentDidMount() { }

  componentDidUpdate() {

    $(".log_arrad[parse!=1]").each(function () {

      $(this).attr('parse', '1');
      $(this).html($(this).text())

    });

  }


  alteraLargura(valor, total) {

    var largura = $('#divIframePhaser').width();
    largura = largura + valor;
    $('#divIframePhaser').css('width', largura + 'px');

    if (total) {

      largura = valor;

    }

    $("#wphaser").html(largura);
    window.studio_blockly.saveSettings();

  }

  alteraAltura(valor, total) {

    var altura = $('#divIframePhaser').height();
    altura = altura + valor;
    $('#divIframePhaser').css('height', altura + 'px');

    if (total) {

      altura = valor;

    }

    $("#hphaser").html(altura);
    window.studio_blockly.saveSettings();

  }

  mostraTableBaixo(id) {

    $('.optionHeaderStudio2').removeClass('ativo');
    $('.optionHeaderStudio2[idc="' + id + '"]').addClass('ativo');

    $('.tabBaixoAsset').fadeOut(0);
    $('.tabBaixoAsset[idc=' + id + ']').fadeIn(0);

    window.phaser.calculaAltura();

  }

  calculaAltura() {

    var altura = 0;
    altura = $(window).height();

    var outros = ($(".tituloProjeto").height() + $("#divIframePhaser").height() + $("#as1").height() + $(".headerLeftStudio2").height() + $(".headerLeftStudio").height() + 67);

    altura = altura - outros;
    $("#log").css('height', altura + 'px');

  }

  startGame(notEmit) {


    window.assets_a.simulateDrag('remove');

    if (!$("#inputPreservarLog").is(':checked')) {

      this.setState({ error: [] });

    }

    $('#IframePhaser').attr('src', $('#IframePhaser').attr('src_old'));




    if (notEmit === true) { return false; };

    var obj = {};

    obj.game = window.studio_blockly.state.game.id;
    obj.user = window.app.state.userId;

    //para emetir para o resto do pessoal
    console.log('emitiu');
    console.log(obj);

    window.emitir('playGame', obj);




  }

  externalLink() {

    window.open(sessionStorage.getItem('backend') + '/games/generatecode/' + this.props.match.params.game + '/', '_blank');

  }

  openAssetStore() {

    // window.$("#assetsStoreModal").modal();
    window.StoreModal.openModal();

  }


  showJs() {

    // $('.optionHeaderStudio').removeClass('ativo'); $('.optionHeaderStudio[type="showJs"]').addClass('ativo'); 
    //$('#divPhaser').fadeOut(0); 
    // $("#divAcerEditor").fadeIn(0); 
    this.setState({ isOpenModalShowJS: true });
    window.studio_blockly.geraJS();

  }



  render() {

    var url = sessionStorage.getItem('backend') + '/games/generatecode/' + window.studio.props.match.params.game + '/';
    var styleHandle = { top: { display: 'none' }, topLeft: { display: 'none' }, left: { marginTop: -5, marginLeft: -3, top: "50%", left: 0, cursor: "ew-resize", border: "3px solid #999", borderTop: "none", borderRight: "none", borderBottom: "none", borderWidth: 5, borderColor: "#09f", width: 10, height: 10, boxSizing: "border-box", zIndex: 1 }, bottomLeft: { marginTop: -7, marginLeft: -2, top: "100%", left: 0, cursor: "nesw-resize", border: "3px solid #999", borderRight: "none", borderTop: "none", borderColor: "#09f", width: 10, height: 10, boxSizing: "border-box", zIndex: 1 }, bottom: { marginTop: -7, marginLeft: -5, top: "100%", left: "50%", cursor: "ns-resize", border: "3px solid #999", borderLeft: "none", borderRight: "none", borderTop: "none", borderWidth: 5, borderColor: "#09f", width: 10, height: 10, boxSizing: "border-box", zIndex: 1 }, bottomRight: { marginTop: -7, marginLeft: -7, top: "100%", left: "100%", cursor: "nwse-resize", border: "3px solid #999", borderLeft: "none", borderTop: "none", borderColor: "#09f", width: 10, height: 10, boxSizing: "border-box", zIndex: 1 }, right: { marginTop: -5, marginLeft: -7, top: "50%", left: "100%", cursor: "ew-resize", border: "3px solid #999", borderTop: "none", borderLeft: "none", borderBottom: "none", borderWidth: 5, borderColor: "#09f", width: 10, height: 10, boxSizing: "border-box", zIndex: 1 }, topRight: { display: 'none' } }



    return (

      <div>

        {
          (window.studio_blockly.state.game.widthcanvas !== undefined) &&

          <center style={{ display: 'none' }}>

            <div className="tituloProjeto">


              {

                (window.studio.state.debug === true) &&
                <font className="eae1" style={{ cursor: 'pointer' }}>

                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 1) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug on</span>}>
                      <div onClick={() => window.studio.debug(false)} className="option option_3 hvr-hang "> <img style={{ height: '36px' }} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>

                  }

                  {

                    (parseInt(window.studio_blockly.state.game.debug) === 0) &&
                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>Debug off</span>}>
                      <div onClick={() => window.studio.debug(1)} className="option option_3 hvr-hang debugon"> <img style={{ height: '36px', filter: 'grayscale(100%)' }} alt="" src="/media/debug.png" /> </div>
                    </Tooltip>

                  }

                </font>


              }


              <img onClick={() => this.startGame()} className="hvr-grow" src="/media/arrow_submit_play_next_right.png" alt="play" style={{ height: '27px', cursor: 'pointer' }} />
              <font> {window.studio_blockly.state.game.name} </font></div>

            <Resizable defaultSize={{ width: window.studio_blockly.state.game.phaserwidth + 'px', height: window.studio_blockly.state.game.phaserheigth + 'px' }} id="divIframePhaser" onResize={(e, direction, ref, d) => { window.phaser.calculaAltura(); $("#wphaser").html($("#divIframePhaser").width()); $("#hphaser").html($("#divIframePhaser").height()); }} onResizeStop={() => window.studio_blockly.saveSettings()} style={{ marginTop: '20px' }} handleStyles={styleHandle}>
              <div>
                {
                  /* Grupo de botões */
                }
              </div>
              <iframe id="IframePhaser" title="phaserframe" src_old={url} frameBorder="0" style={{ boxShadow: '0 7px 30px -10px rgb(150 170 180 / 50%)', width: '100%', height: '100%', background: '#fff' }} ></iframe>
            </Resizable>

            <div style={{ marginTop: '5px', color: "#fff" }} id="as1">

              <button className="alterCanvasSize" onClick={() => this.alteraLargura(-1)}><i className="fas fa-minus" style={{ color: '#fff' }}></i></button> <button onClick={() => this.alteraLargura(1)} className="alterCanvasSize"><i className="fas fa-plus" style={{ color: '#fff' }}></i></button> <font id="wphaser" >{window.studio_blockly.state.game.phaserwidth}</font> x <font id="hphaser">{window.studio_blockly.state.game.phaserheigth}</font>
              <button className="alterCanvasSize" onClick={() => this.alteraAltura(1)}><i style={{ marginLeft: '4px', color: '#fff' }} className="fas fa-plus"></i></button> <button onClick={() => this.alteraAltura(-1)} className="alterCanvasSize"><i className="fas fa-minus" style={{ color: '#fff' }}></i></button>

            </div>

            <div style={{ color: "#fff" }}><font style={{ marginRight: '29px' }}>Eixo x: <font id="eixo_x">0</font> </font> <font>Eixo y: <font id="eixo_y">0</font></font></div>

          </center>

        }

        <div className="headerLeftStudio2">

          <div hidden onClick={() => this.mostraTableBaixo('TableScenes')} className="optionHeaderStudio2" idc="TableScenes" type="showScenes"><font>Scenes <i onClick={() => window.studio_blockly.newFileByName('scene')} className="addAlgo">+</i></font></div>
          <div onClick={() => this.mostraTableBaixo('TableFiles')} className="optionHeaderStudio2 ativo" idc="TableFiles" type="showJs">



            {

              (window.studio_blockly.state.game.advanced === 1) &&
              <span>Files</span>

            }

            {

              (window.studio_blockly.state.game.advanced === 0) &&
              <span>Scenes <FcGallery /></span>

            }

            <Popup
              trigger={
                <button hidden className="addAlgo" onClick={() => window.studio_blockly.newFileByName()}>
                  <IconContext.Provider value={{ color: "blue", className: "addAlgoIcon" }}>
                    <IoMdAdd />
                  </IconContext.Provider>
                </button>
              }
              position="bottom right"
              on="hover"
              closeOnDocumentClick={true}
              onClose={() => {
                //this.setState({ isOpen: false })
              }}
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
              arrow={true}

            >
              <span className="text_poup_tippy">Criar nova cena.</span>
            </Popup>



          </div>

          <div
            onClick={() => this.mostraTableBaixo('TableAssets')}
            className="optionHeaderStudio2 "
            idc="TableAssets" type="showCanvas" >


            <span>Assets <FcFolder /></span>

            <Popup
              trigger={
                <button className="addAlgo" onClick={() => window.uploadAssetModal.openUploadAssetModal(window.$(".optionHeaderAsset.ativo").attr('idc'))}>
                  <IconContext.Provider value={{ className: "addAlgoIcon" }}>
                    <IoMdAdd />
                  </IconContext.Provider>
                </button>
              }
              position="bottom right"
              on="hover"
              closeOnDocumentClick={true}
              onClose={() => {
                //this.setState({ isOpen: false })
              }}
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
              arrow={true}

            >
              <span className="text_poup_tippy">Importar um novo arquivo.</span>
            </Popup>





          </div>


          <Popup
            trigger={
              <div className="btn_menu_phaser" onClick={{}}>
                <Hamburger


                  toggled={this.state.isOpen}
                  toggle={(e) => {
                    this.setState({ isOpen: e })
                  }}


                />
              </div>
            }
            position="left top"
            on="click"
            closeOnDocumentClick={true}
            onClose={() => this.setState({ isOpen: false })}
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '10px' }}
            arrow={false}
            open={this.state.isOpen}
          >
            <div>
              <div className="content_dropdown_navbar_studio">
                <div className="menu-item"> <button onClick={() => { this.props.history.push("/"); this.setState({ isOpen: false }) }}>Voltar a página inicial</button></div>
                <div className="menu-item"> <button onClick={() => { window.gameConfigurationModal.resetConfiguration(); this.setState({ isOpen: false }) }} >Configurações do projeto</button></div>
                <div className="menu-item"> <button onClick={() => { this.externalLink();; this.setState({ isOpen: false }) }} >Abrir em nova aba</button></div>
                <div className="menu-item"> <button onClick={() => { window.studio_blockly.regenerateAllCodes(true);; this.setState({ isOpen: false }) }} >Recompilar</button></div>
                <div className="menu-item"> <button onClick={() => { this.openAssetStore();; this.setState({ isOpen: false }) }} >Asset Store</button></div>
                <div className="menu-item"> <button onClick={() => { window.phaser.startGame();; this.setState({ isOpen: false }) }}>Reiniciar Projeto</button></div>


                {
                  (this.state.debug === true) &&

                  <div className="menu-item"> <button onClick={() => {
                    if (parseInt(window.studio_blockly.state.game.debug) === 1) {
                      this.debug(false);
                    } else {
                      this.debug(1);
                    }
                  }}>Debug {parseInt(window.studio_blockly.state.game.debug) === 1 ? <span style={{ color: "blue" }}>{"(Ativo)"}</span> : <span style={{ color: "red" }}>{"(Desativado)"}</span>}</button>
                  </div>
                }


              </div>
            </div>
          </Popup>







          <div hidden onClick={() => this.mostraTableBaixo('TableConsole')} className="optionHeaderStudio2" idc="TableConsole" type="showJs"><font>Console</font> <font style={{ float: 'right', marginRight: '25px' }}>{this.state.error.length}</font> </div>

        </div>

        <div id="phaserTableBaixo">

          <div className="tabBaixoAsset" idc="TableAssets" style={{ display: 'none' }}><TableAssets /></div>
          <div className="tabBaixoAsset" idc="TableFiles" ><FileEdit /></div>
          <div className="tabBaixoAsset" idc="TableConsole" style={{ display: 'none' }}>

            <Tooltip placement="right" trigger={['hover']} overlay={<span>Limpar logs?</span>}>
              <i onClick={() => this.setState({ error: [] })} className="fas fa-recycle" style={{ "position": "absolute", "height": "18px", "fontSize": "15px", "marginTop": "11px", "cursor": "pointer", "marginLeft": "14px" }}></i>
            </Tooltip>
            <div>

              <center>

                <Tooltip placement="right" trigger={['hover']} overlay={<span>Mantem os logs ao reiniciar.</span>}>

                  <div className="form-check form-check-inline" style={{ marginTop: '7px' }}>
                    <input onChange={() => window.studio_blockly.saveSettings()} className="form-check-input" type="checkbox" id="inputPreservarLog" value="option1" />
                    <label className="form-check-label" htmlFor="inputPreservarLog" style={{ color: '#fff' }}>Preservar log</label>
                  </div>

                </Tooltip>

              </center>



            </div>

            <div id="log" style={{ padding: '0px 10px 0px 10px', marginTop: '9px', overflowY: 'auto' }}>

              {

                (this.state.error.length >= 0) &&
                this.state.error.map(function (item, i) {

                  var img = "";
                  var classp = "alert alert-default alert-dismissible animated fadeInUp logdiv ";

                  if (item.type === "log") { img = "/media/symbol_information.png"; }
                  if (item.type === "info") { classp = classp + " "; img = "/media/symbol_information.png"; }
                  if (item.type === "warn") { classp = classp + " logwarn"; img = "/media/symbol_exclamation.png"; }
                  if (item.type === "error") { classp = classp + " logerror"; img = "/media/symbol_error.png"; }

                  return (<div key={i} style={{ backgroundColor: '#f7f7f7', boxShadow: '0px 0px 0px 0.2px #000', paddingRight: '15px' }} className={classp}>
                    <button style={{ display: 'none' }} type="button" className="close" data-dismiss="alert"><span aria-hidden="true" hidden>×</span><span className="sr-only">Close</span></button>
                    <img style={{ height: '30px', marginRight: '5px' }} src={img} alt="" />
                    <div className="log_arrad" style={{ display: 'contents' }}>{item.msg}</div>
                  </div>)

                })

              }

              {

                (this.state.error.length === 0) &&
                <div className="nenhumlog animated fadeIn">

                  <font>Nenhum log informado.</font>

                </div>

              }


            </div>

          </div>



        </div>



        <div className="content_menu_settings_phaser_TableBaixo">



          <>
            {
              /*
              
                      <Popup
                        trigger={
                          <button className="btn_color_2" style={{ fontSize: '20px' }}><GiResize />
            
                          </button>
                        }
                        position="top right"
                        on="click"
                        closeOnDocumentClick={true}
                        onClose={() => this.setState({ isOpenDimensionsIframe: false })}
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        contentStyle={{ border: 'none', marginLeft: '0px' }}
                        arrowStyle={{ stroke: "#FFF", color: "#FFF", marginTop: '1px' }}
                        arrow={true}
                        open={this.state.isOpenDimensionsIframe}
                      >
                        <div>
                          <div className="content_dropdown_navbar_studio_dimensions">
                            <div className="menu-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <h1 className="title_dropdown_navbar_studio_dimensions">Dimensões do jogo</h1>
                            </div>
                            <div className="menu-item">
            
                              <span className="span_name_canvasSize">Largura: </span>
                              <div className="menu_item_group_buttons">
                                <button
                                  className="alterCanvasSize remove_canvasSeize "
                                  onClick={() => {
                                    this.alteraLargura(-1);
                                  }}
            
                                >
                                  <i className="fas fa-minus" style={{ color: '#000' }}></i>
                                </button>
            
            
                                <span id="wphaser" className="value_canvasSize">{window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserwidth : "Carregando..."}</span>
            
                                <button
                                  onClick={() => {
                                    this.alteraLargura(1);
                                  }}
            
                                  className="alterCanvasSize add_canvasSeize">
                                  <i className="fas fa-plus" style={{ color: '#000' }}></i>
                                </button>
                              </div>
                            </div>
                            <div className="menu-item">
                              <span className="span_name_canvasSize">Altura: </span>
            
                              <div className="menu_item_group_buttons">
                                <button
                                  onClick={() => {
                                    this.alteraAltura(-1);
                                  }}
            
                                  className="alterCanvasSize remove_canvasSeize">
                                  <i className="fas fa-minus" style={{ color: '#000' }}></i>
                                </button>
                                <span id="hphaser" className="value_canvasSize">{window.studio_blockly !== undefined ? window.studio_blockly.state.game.phaserheigth : "Carregando..."}</span>
                                <button
                                  className="alterCanvasSize add_canvasSeize"
                                  onClick={() => {
                                    this.alteraAltura(1);
                                  }}
                                >
                                  <i style={{ marginLeft: '4px', color: '#000' }} className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popup>
            
            */
            }

          </>

          <Popup
            trigger={
              <button onClick={() => this.setState({ isOpenTerminal: true })} className="btn_color_1"><DiTerminalBadge /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Console.</span>
          </Popup>

          <Modal
            isOpen={this.state.isOpenTerminal}
            onAfterOpen={() => {
              this.setState({ isOpenTerminal: true });

            }}
            onRequestClose={() => {
              this.setState({ isOpenTerminal: false });

            }}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button className="close_modal_btn" onClick={() => this.setState({ isOpenTerminal: false })}><CgClose /></button>
            <div id="log">

              <Tooltip placement="right" trigger={['hover']} overlay={<span>Limpar logs ?</span>}>
                <i onClick={() => this.setState({ error: [] })} className="fas fa-recycle" style={{ "position": "absolute", "height": "18px", "fontSize": "15px", "marginTop": "11px", "cursor": "pointer", "marginLeft": "14px" }}></i>
              </Tooltip>
              <div>

                <center>

                  <Tooltip placement="right" trigger={['hover']} overlay={<span>Mantem os logs ao reiniciar.</span>}>

                    <div className="form-check form-check-inline" style={{ marginTop: '7px' }}>
                      <input onChange={() => window.studio_blockly.saveSettings()} className="form-check-input" type="checkbox" id="inputPreservarLog" value="option1" />
                      <label className="form-check-label" htmlFor="inputPreservarLog" style={{ color: '#fff' }}>Preservar log</label>
                    </div>

                  </Tooltip>

                </center>



              </div>
              {

                (this.state.error.length >= 0) &&
                this.state.error.map(function (item, i) {

                  var img = "";
                  var classp = "alert alert-default alert-dismissible  logdiv ";

                  if (item.type === "log") { img = "/media/symbol_information.png"; }
                  if (item.type === "info") { classp = classp + " "; img = "/media/symbol_information.png"; }
                  if (item.type === "warn") { classp = classp + " logwarn"; img = "/media/symbol_exclamation.png"; }
                  if (item.type === "error") { classp = classp + " logerror"; img = "/media/symbol_error.png"; }

                  return (<div key={i} style={{ backgroundColor: '#f7f7f7', paddingRight: '15px' }} className={classp}>
                    <button style={{ display: 'none' }} type="button" className="close" data-dismiss="alert"><span aria-hidden="true" hidden>×</span><span className="sr-only">Close</span></button>
                    <img style={{ height: '30px', marginRight: '5px' }} src={img} alt="" />
                    <div className="log_arrad" style={{ display: 'contents' }}>{item.msg}</div>
                  </div>)

                })

              }

              {

                (this.state.error.length === 0) &&
                <div className="nenhumlog animated fadeIn" style={{ color: "#000" }}>

                  <font>Nenhum log informado.</font>

                </div>

              }
            </div>

          </Modal>

          <Popup
            trigger={
              <button
                onClick={() => this.setState({ isOpenModalShowJS: true })}
                className="btn_js_menu_settings btn_color_3"><DiJavascript1 /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Visualizar o código do projeto.</span>
          </Popup>


          <Modal
            isOpen={this.state.isOpenModalShowJS}
            onAfterOpen={() => {
              this.setState({ isOpenModalShowJS: true });

            }}
            onRequestClose={() => {
              this.setState({ isOpenModalShowJS: false });

            }}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <button className="close_modal_btn" onClick={() => this.setState({ isOpenModalShowJS: false })}><CgClose /></button>


            {

              window.studio_blockly !== undefined &&

              <AceEditorMaster />

            }


          </Modal>



          <Popup
            trigger={
              <button className="btn_color_4" onClick={() => { window.studio_blockly.regenerateAllCodes(true);; this.setState({ isOpen: false }) }} ><IoReload /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Recarregar o jogo.</span>
          </Popup>

          <Popup
            trigger={
              <button className="btn_color_2" onClick={() => { window.gameConfigurationModal.resetConfiguration(); this.setState({ isOpen: false }) }} ><FiSettings /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Configurações do projeto.</span>
          </Popup>

          <Popup
            trigger={
              <button className="btn_color_1" onClick={() => { window.studio_blockly.regenerateAllCodes(true);; this.setState({ isOpen: false }) }} ><IoIosConstruct /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Recompilar o jogo.</span>
          </Popup>

          <Popup
            trigger={
              <button className="btn_color_3" onClick={() => { this.externalLink();; this.setState({ isOpen: false }) }} ><VscEmptyWindow /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Abrir o jogo em nova uma nova janela.</span>
          </Popup>

          <Popup
            trigger={
              <button className="btn_color_4" onClick={() => { this.openAssetStore();; this.setState({ isOpen: false }) }} ><IoMdAppstore /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Gerenciador de Arquivos Compartilhados.</span>
          </Popup>


          <Popup
            trigger={
              <button className="btn_color_2" onClick={() => { this.props.history.push("/"); this.setState({ isOpen: false }) }}><IoHome /></button>
            }
            position="top right"
            on="hover"
            closeOnDocumentClick={true}
            onClose={() => {
              //this.setState({ isOpen: false })
            }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
            arrow={true}
          >
            <span className="text_poup_tippy">Voltar para o Início.</span>
          </Popup>






          {
            (this.state.debug === true) &&

            <button
              className="btn_color_1"
              onClick={() => {
                if (parseInt(window.studio_blockly.state.game.debug) === 1) {
                  this.debug(false);
                } else {
                  this.debug(1);
                }
              }}
              style={{ color: parseInt(window.studio_blockly.state.game.debug) === 1 ? "green" : "" }}
            ><IoBugSharp /></button>

          }



        </div>

      </div >
    )


  }


}


export default Phaser;