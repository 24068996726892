import React, { useEffect } from 'react';

import $ from 'jquery';

export default function VerifyUpdate(props) {



	useEffect(() => {
		for (var i = 1; i <= $(".blocklyTreeRoot [role='group']").length; i++) {

			var name = $(".blocklyTreeRoot .blocklyTreeItem:nth-child(" + i + ") .blocklyTreeLabel").text();

			if (name === "") {
				i = $(".blocklyTreeRoot [role='group']").length;
				//				console.log("TESTE SEM NOME");
				return
			}

			$(".blocklyTreeRoot .blocklyTreeItem:nth-child(" + i + ") .blocklyTreeLabel").addClass("icon_toolbox_class icon_toolbox_" + name);



			//console.log("TESTE: ", name);


		}

	}, [
		$(".blocklyTreeLabel")
	]);



	return (
		<>

		</>
	)
}


