import React, { Component } from 'react';
import { SortablePane, Pane } from 'react-sortable-pane';
import api from './../Api';
//import Tooltip from 'rc-tooltip';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { FaEye, FaTrash, FaFileImport } from 'react-icons/fa'
import {IoMdImages } from 'react-icons/io'
import { MdAudiotrack, MdInsertDriveFile, } from 'react-icons/md'
//import { FcImageFile, FcFile, FcAudioFile } from 'react-icons/fc'


require('dotenv').config();

class Assets extends Component {

  constructor(props) {

    super(props);
    this.state = { SortablePane: true, order: [], simulaDrag: false };
    this.updateOrder = this.updateOrder.bind(this);
    this.changeEditorType = this.changeEditorType.bind(this);

    window.assets_a = this;

  }

  renderAudio(value) {

    //var url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+value.file.url;
    //var url = value.file.url;
    var url = sessionStorage.getItem('backend') + value.upload.url;

    return (

      <Popup
        trigger={
          <div className="boxAsset audioAsset">

            {/* 
    <center><img style={{height:'30px'}} src="/media/play.png" onClick={ () => window.lity(url) } alt=""/></center>
  */}

            <AudioPlayer src={url} onPlay={e => {
              //console.log("onPlay")
            }} />

          </div>
        }
        position="bottom right"
        on="hover"
        closeOnDocumentClick={true}
        onClose={() => {
          //this.setState({ isOpen: false })
        }}
        mouseLeaveDelay={0}
        mouseEnterDelay={0}
        contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
        arrow={true}

      >
        <span className="text_poup_tippy">Visualizar arquivo.</span>
      </Popup>



    )

  }

  renderImage(value) {

    //var url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+value.file.url;
    //var url = value.file.url;
    var url = sessionStorage.getItem('backend') + value.upload.url;

    return (
      <Popup
        trigger={
          <div className="boxAsset imageAsset" idc={value.id}>

            <img src={url} onClick={() => window.lity(url)} alt="" style={{ borderRadius: '4px' }} />

          </div>
        }
        position="bottom right"
        on="hover"
        closeOnDocumentClick={true}
        onClose={() => {
          //this.setState({ isOpen: false })
        }}
        mouseLeaveDelay={0}
        mouseEnterDelay={0}
        contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
        arrow={true}

      >
        <span className="text_poup_tippy">Visualizar arquivo.</span>
      </Popup>


    )

  }

  updateOrder() {

    this.state.order.forEach(function (item, index, array) {

      api.updateAsset(item, { sequence: index }).then((res) => {

      }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        return false;

      });

    });

  }

  mostraTableAsset(id) {

    this.setState({ assetAbaDefault: id });
    setTimeout(function () { window.studio_blockly.saveSettings(); }, 200);


  }


  //para mudar o tipo de editor caso tenha arquivo relacionado
  changeEditorType(file, tipo) {

    console.log('file: ' + file);
    console.log('tipo: ' + tipo);

  }


  simulateDrag(id) {

    if (id === "remove") {

      this.setState({ simulaDrag: false });
      window.$("#simula_drag").remove();
      return false;

    }

    var asset = window.$('.imageAsset[idc=' + id + ']');
    console.log('.imageAsset[idc=' + id + ']');

    var src = asset.find('img').attr('src');

    this.setState({ simulaDrag: true });

    if (window.$('#simula_drag').length === 0) {

      window.$("#divIframePhaser").append('  <img id="simula_drag" src="' + src + '" style="position:absolute;pointer-events:none;left:0px;top:0px;"/>  ');

    } else {

      this.setState({ simulaDrag: false });
      window.$("#simula_drag").remove();

    }
    //se o elemento existe, eu crio


  }


  render() {


    var assets = {};
    window.defaultAssetsType.map((value, index) => {

      assets[value.name] = [];
      window.studio_blockly.state.Assets.map((value2, index2) => {

        if (parseInt(value2.type) === parseInt(value.id)) { assets[value.name].push(value2); }
        return "";

      });

      return "";

    });


    var className;
    var className2;

    var cp1, cp2;
    if (window.studio_blockly.state.game.advanced) {

      cp1 = "col-md-3";
      cp2 = "col-md-2";

    } else {

      cp1 = "col-md-12";
      cp2 = "col-md-3 hidden_div";

    }

    //console.log(assets);

    return (<>


      <div style={{ display: 'flex', margin: '10px 0px' }}>

        {

          window.defaultAssetsType.map((value, index) => {
            var icon = <> </>;
            //MdAudiotrack, MdInsertDriveFile, IoMdImages
            if (parseInt(this.state.assetAbaDefault) === parseInt(value.id)) { className = "optionHeaderAsset ativo"; } else { className = "optionHeaderAsset"; }

            if (value.name === "Image") {
              icon = <IoMdImages />
            }

            if (value.name === "SpriteSheet") {
              icon = <MdInsertDriveFile />
            }

            if (value.name === "Áudio") {
              icon = <MdAudiotrack />
            }

            return (
              <div
                key={value.id}
                className={className}
                onClick={() => this.mostraTableAsset(value.id)}
                idc={value.id}
                style={{ width: "50%", textAlign: 'center' }}>


                {icon}
                <p>{value.name}</p>
              </div>
            );

          })

        }

      </div>

      <input hidden id="inputAsset" type="file" onChange={() => window.studio_blockly.sendAsset()} />

      {(this.state.order.length !== 0 && this.state.SortablePane) &&

        window.defaultAssetsType.map((value2, index2) => {

          if (parseInt(this.state.assetAbaDefault) === parseInt(value2.id)) { className2 = "optionDivAsset ativo"; } else { className2 = "optionDivAsset"; }

          return (

            <div className={className2} idc={value2.id} key={index2} >

              {

                (assets[value2.name].length !== 0) &&
                <div className="row rowTitlebaixo">
                  <div className="col-md-2"></div>
                  <div className="col-md-2">Preview</div>
                  <div className={cp1}>Key</div>
                  <div className={cp2}>Editor</div>
                  <div hidden>Tipo</div>
                  <div className="col-md-3"><center>Ações</center></div>
                </div>

              }


              {

                (assets[value2.name].length === 0) &&
                <div style={{ fontSize: '20px', marginTop: '15px' }}><center>{value2.t} {value2.name2} foi adicionado.</center></div>

              }


              <SortablePane
                resizable={{ x: false, y: false, xy: false }}
                dragHandleClassName="movedor"
                direction="vertical"
                margin={5}
                onOrderChange={order => { this.setState({ order }); }}
                onDragStop={order => { this.updateOrder(); }}
                disableEffect={true}
              >

                {

                  assets[value2.name].map((value, index) => {

                    //var url = process.env.REACT_APP_SERVER_URL+':'+process.env.REACT_APP_PORT+value.file.url;
                    //var url = value.file.url;


                    //                console.log(value);

                    return (
                      <React.Fragment key={value.id} >

                        <Pane

                          className="__resizable_base__"

                          defaultSize={{ width: '100%', height: '75px', }}
                        >

                          <div style={{ display: 'flex', alignItems: 'center' }}>

                            <div className="container_item_fileEdit " idc={value.id}>

                              <div className="content_left_item_fileEdit">



                                <div

                                  className="btn_eye_fileEdit"
                                >

                                  {

                                    (value.upload.ext === "svg" || value.upload.ext === "jpg" || value.upload.ext === "png" || value.upload.ext === "gif") &&
                                    this.renderImage(value)

                                  }

                                  {

                                    (value.upload.ext === "mp3") &&
                                    this.renderAudio(value)

                                  }

                                </div>



                                <Popup
                                  trigger={
                                    
                                    <input
                                      onKeyUp={(event) => window.studio_blockly.updateNameAsset(value.id, event.target.value)}
                                      file_id={value.file.id}
                                      defaultValue={value.name}
                                      className="input_name_fileEdit"
                                      style={{ marginLeft: '10px ',pointerEvents:'none'}}
                                    />
                                    
                                  }
                                  position="bottom right"
                                  on="hover"
                                  closeOnDocumentClick={true}
                                  onClose={() => {
                                    //this.setState({ isOpen: false })
                                  }}
                                  mouseLeaveDelay={0}
                                  mouseEnterDelay={0}
                                  contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                                  arrow={true}

                                >
                                  <span className="text_poup_tippy">Renomear.</span>
                                </Popup>

                              </div>

                              <div className="content_right_item_fileEdit">


                                <div className="content_group_btn_red_file_edit">

                                  {

                                    (parseInt(value.type) === 1) &&


                                    <Popup
                                      trigger={
                                        <button onClick={() => window.animationModal.openAnimationModal(value.id)}>
                                          <i className="fas fa-cog" style={{ cursor: 'pointer' }}></i>
                                        </button>
                                      }
                                      position="bottom right"
                                      on="hover"
                                      closeOnDocumentClick={true}
                                      onClose={() => {
                                        //this.setState({ isOpen: false })
                                      }}
                                      mouseLeaveDelay={0}
                                      mouseEnterDelay={0}
                                      contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                                      arrow={true}

                                    >
                                      <span className="text_poup_tippy">Configurar animações.</span>
                                    </Popup>




                                  }

                                  {

                                    (window.studio_blockly.state.game.advanced === true) &&

                                    <Popup
                                      trigger={
                                        <button onClick={() => window.AssocitionModal.openAssocitionModal(value.id)}>
                                          <i className="fa fa-code" style={{ cursor: 'pointer' }}></i>
                                        </button>
                                      }
                                      position="bottom right"
                                      on="hover"
                                      closeOnDocumentClick={true}
                                      onClose={() => {
                                        //this.setState({ isOpen: false })
                                      }}
                                      mouseLeaveDelay={0}
                                      mouseEnterDelay={0}
                                      contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                                      arrow={true}

                                    >
                                      <span className="text_poup_tippy">Associação.</span>
                                    </Popup>



                                  }


                                  {
                                    (parseInt(value.type) !== 3) &&

                                    <Popup
                                      trigger={
                                        <button
                                          onClick={() => window.ImportAsseAsset.openModalImportAsseAsset(value.id)}>
                                          <FaFileImport />
                                        </button>
                                      }
                                      position="bottom right"
                                      on="hover"
                                      closeOnDocumentClick={true}
                                      onClose={() => {
                                        //this.setState({ isOpen: false })
                                      }}
                                      mouseLeaveDelay={0}
                                      mouseEnterDelay={0}
                                      contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                                      arrow={true}

                                    >
                                      <span className="text_poup_tippy">Vincular.</span>
                                    </Popup>


                                  }

                                </div>



                                <Popup
                                  trigger={
                                    <button className="btn_lock_fileEdit mt-0" onClick={() => window.studio_blockly.removeAsset(value.id)}>
                                      <FaTrash />
                                    </button>
                                  }
                                  position="bottom right"
                                  on="hover"
                                  closeOnDocumentClick={true}
                                  onClose={() => {
                                    //this.setState({ isOpen: false })
                                  }}
                                  mouseLeaveDelay={0}
                                  mouseEnterDelay={0}
                                  contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                                  arrow={true}

                                >
                                  <span className="text_poup_tippy">Excluir.</span>
                                </Popup>




                              </div>
                            </div>


                            <Popup
                              trigger={
                                <button className="btn_dragglable_item_fileEdit" onClick={() => window.studio_blockly.preGetFile(value.file.id)}>
                                  <FaEye />
                                </button>
                              }
                              position="bottom right"
                              on="hover"
                              closeOnDocumentClick={true}
                              onClose={() => {
                                //this.setState({ isOpen: false })
                              }}
                              mouseLeaveDelay={0}
                              mouseEnterDelay={0}
                              contentStyle={{ border: 'none', marginLeft: '0px', padding: '10px', backgroundColor: "#fb8500" }}
                              arrow={true}

                            >
                              <span className="text_poup_tippy">Editar.</span>
                            </Popup>


                          </div>

                        </Pane>

                      </React.Fragment>
                    )



                  })


                }

              </SortablePane></div>)

        })



      }

      {(window.studio_blockly.state.Assets.length === 0) &&

        <div className="nenhumlog animated fadeIn"><font>Nenhum asset foi enviado.</font></div>

      }

    </>);

  }


}

export default Assets;
