import React, { Component } from 'react';
import $ from 'jquery';
import api from './../Api';
import axios from "axios";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'

class UploadAssetModal extends Component {

	constructor(props) {

		super(props);
		this.state = { erro: "", isOpenModal: false }
		this.alteraValor = this.alteraValor.bind(this);
		this.enviaAsset = this.enviaAsset.bind(this);
		this.sendFile = this.sendFile.bind(this);
		this.openUploadAssetModal = this.openUploadAssetModal.bind(this);
		window.uploadAssetModal = this;

	}

	//window.$('#inputAsset').click()
	openUploadAssetModal(type) {

		this.setState({ a: 1, filePreview: undefined, isOpenModal: true });
		//window.$("#uploadAssetModal").modal();
		//document.getElementById("formSendAsset").reset();
		$("#tipoAssetEnviado").val(type);
		$("#id_file_asset").val('');

		this.changeTipo();

	}

	alteraValor(event) {

		console.log(event.target.value);
		console.log(event.target.name);

		var valores = this.state.valores;
		console.log(valores);

		valores[event.target.name] = event.target.value;

		console.log(valores);

		this.setState({ valores: valores });

	}

	async sendFile() {

		var formData = new FormData();

		var imagefile = document.querySelector('#inputUploadAsset');
		formData.append("files", imagefile.files[0]);

		//var post = await axios.post(window.url+'/upload', formData, { headers: {'Content-Type': 'multipart/form-data'}});
		await axios.post(sessionStorage.getItem('backend') + '/upload', formData, { headers: {session: sessionStorage.getItem('session'),'Content-Type': 'multipart/form-data' } }).then(function (response) {

			this.setState({ filePreview: response.data });
			$("#id_file_asset").val(response.data.id);

		}.bind(this)).catch(function (error) { this.setState({ error: error.response.data.msg }); console.log(error.response.data); }.bind(this));

	}

	async importAssetStore(data) {


		data = data.data_file;
		data.url = window.urlstorebackend + data.url;

		console.log(data);
		//return false;

		await axios.post(sessionStorage.getItem('backend') + '/importAsset', data).then(function (response) {

			console.log(data);
			this.setState({ filePreview: response.data });
			$("#id_file_asset").val(response.data.id);

		}.bind(this)).catch(function (error) { this.setState({ error: error.response.data.msg }); console.log(error.response.data); }.bind(this));

	}

	async enviaAsset() {

		var key = $("#keyNovoAsset").val();

		//let formData = new FormData();

		if (this.state.filePreview === undefined) { return false; }


		var gamefile = [];
		$(".checkSelectImport44:checked").each(function () {

			gamefile.push($(this).attr('idc'));

		});


		//faz o upload somente do arquivo
		var asset = this.state.filePreview;
		asset.name = key;

		//cria o asset
		asset = await window.studio_blockly.newAsset(asset);
		console.log(asset);
		await api.addAssetScene(asset.id, gamefile).then((res) => {

			console.log(res.data);

		}).catch(error => {

			console.log(error.data);
			console.log(error);
			console.log(error.response);

		});

		window.$('div').modal('hide');
		//await window.studio_blockly.updateAsset(asset.id,'gamefile',gamefile);
		await window.studio_blockly.getAllFiles();

		await this.setState({ isOpenModal: false });

	}

	changeTipo() {

		if (parseInt($("#tipoAssetEnviado").val()) === 3) {

			$('.checkSelectImport44').prop('checked', false);
			$('#quaisAssetsImportados').fadeOut(0);

		} else {

			$('#quaisAssetsImportados').fadeIn(0);

		}

	}

	render() {

		return (


			<Popup
				open={this.state.isOpenModal}
				closeOnDocumentClick
				onOpen={() => { }}
				onClose={() => { this.setState({ isOpenModal: false }); }}
				modal>

				<div className="title_modal_uploadAsset">
					<h1>Importar um novo arquivo</h1>
				</div>



				<form id="formSendAsset">
					<div className="form-group">
						<label htmlFor="exampleInputEmail3" className="title_label_input_group">key:</label>
						<input type="text" name="key" className="form-control" id="keyNovoAsset" required="" />
					</div>

					<div className="form-group">
						<label className="title_label_input_group">Tipo:</label>
						<select onChange={(eve) => this.changeTipo(eve)} type="text" name="tipo" id="tipoAssetEnviado" className="form-control" >

							{

								window.defaultAssetsType.map(function (item, i) {

									return (<option value={item.id} key={item.id}>{item.name}</option>)

								})

							}

						</select>
					</div>

					<div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
						<div style={{ width: '50%' }}>
							<div className="form-group">
								<label htmlFor="exampleInputEmail3" className="title_label_input_group">Arquivo:</label>
								<input onChange={() => this.sendFile()} type="file" name="key" id="inputUploadAsset" style={{ width: '100%' }} />
							</div>
						</div>
						<div style={{ width: '50%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
							<p style={{ marginBottom: '0.5rem' }} className="title_label_input_group">Pre-visualização</p>
							{
								(this.state.filePreview !== undefined) &&
								<img src={sessionStorage.getItem('backend') + this.state.filePreview.url} alt="..." style={{ borderRadius: '6px', height: '50px' }} />
							}

							{
								(this.state.filePreview === undefined) &&
								<div style={{ backgroundColor: "#F6F6F6", borderRadius: '6px', height: '50px', width: '50px', display: 'block', content: ' ' }}>

								</div>
							}


						</div>
					</div>


					<div className="" id="quaisAssetsImportados">

						<div className="title_label_input_group" style={{ marginBottom: '0.5rem' }}>Em quais cenas será importado?</div>


						<div className="card__group">

							{

								window.studio.returnAllScenes().map(function (item, i) {

								//	console.log("item: ", item)
									var key = "checkinputsencs11az" + item.id;

									return (

										<div key={item.id} className="card">
											<input hidden className="checkSelectImport44 checkBoxScene custom" type="checkbox" id={key} idc={item.id} value={item.id} />
											<label htmlFor={key}>
												<h5>{item.name}</h5>
											</label>
										</div>

									)

								})

							}

						</div>

						{

							(window.studio.returnAllScenes().length === 0) &&
							<center>Nenhuma cena foi encontrada.</center>

						}



					</div>


					<div className="">

						<button type="button" className="btn btn-primary btn_send_asset_modal" style={{ 'width': '100%', height: '50px' }} onClick={() => this.enviaAsset()} >Enviar Asset <i className="fas fa-file-upload"></i></button>

					</div>



				</form>
			</Popup>



		)

	}

}



export default UploadAssetModal;


/*


			<div className="modal fade active" id="uploadAssetModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">

						<br />
						<center><h3>Novo asset?</h3></center>

						<div className="modal-body">

							<form id="formSendAsset">

								<div className="row">

									<div className="col-md-6">

										<div className="col-md-12">
											<div className="form-group">
												<label htmlFor="exampleInputEmail3">key:</label>
												<input type="text" name="key" className="form-control" id="keyNovoAsset" required="" />
											</div>
										</div>

										<div className="col-md-12">
											<div className="form-group">
												<label>Tipo:</label>
												<select onChange={(eve) => this.changeTipo(eve)} type="text" name="tipo" id="tipoAssetEnviado" className="form-control" >

													{

														window.defaultAssetsType.map(function (item, i) {

															return (<option value={item.id} key={item.id}>{item.name}</option>)

														})

													}

												</select>
											</div>
										</div>

										<div className="col-md-12">
											<div className="form-group">
												<label htmlFor="exampleInputEmail3">Arquivo:</label>
												<input onChange={() => this.sendFile()} type="file" name="key" id="inputUploadAsset" style={{ width: '100%' }} />
											</div>
										</div>

									</div>

									<div className="col-md-6" id="quaisAssetsImportados">

										<div style={{ marginBottom: '10px' }}>Em quais senas será importado?</div>

										{

											window.studio.returnAllScenes().map(function (item, i) {

												var key = "checkinputsencs11az" + item.id;

												return (<div key={item.id} className="col-md-12" style={{ display: 'flex' }}>
													<input className="checkSelectImport44 checkBoxScene" type="checkbox" id={key} idc={item.id} value={item.id} />
													<label htmlFor={key} style={{ lineHeight: '11px', marginLeft: '5px' }}>{item.name}</label>
												</div>)

											})

										}

										{

											(window.studio.returnAllScenes().length === 0) &&
											<center>Nenhuma scene foi encontrada.</center>

										}

										<center style={{ marginTop: '10px', fontWeight: '600' }}>Preview</center>
										<div>

											{

												(this.state.filePreview !== undefined) &&
												<center>
													<div className="post-image">

														{ }
														<img src={sessionStorage.getItem('backend') + this.state.filePreview.url} alt="..." />

													</div>
												</center>
											}


										</div>

									</div>

									<div className="col-md-12">

										<button type="button" className="btn btn-primary" style={{ 'width': '100%' }} onClick={() => this.enviaAsset()} >Enviar Asset <i className="fas fa-file-upload"></i></button>

									</div>

								</div>

							</form>

						</div>




					</div>
				</div>

				<style>{'.post-image img {   position: absolute;  margin: auto;  top: 0;  left: 0;  right: 0;  bottom: 0;  max-height: 190px;  padding: 25px;}.post-image {  height: 160px;}.post-image {position: relative;z-index: 1;}'}</style>

			</div>

			*/